import React from 'react'
import UserSettingCon from './UserSettingCon'

const UserAccountInfo = () => {
  return (
    <UserSettingCon>
      <div className="col-xl-8 col-md-7 ">
        <div className="page-title">
          Account Info
        </div>

        <div className="input-info-box mt-30">
          <div className="header">
            Personal Info
          </div>
          <div className="content">
            <div className="row">
              <div className="col-md-6">
                <div className="my-input-box">
                  <label htmlFor="">Profile Name</label>
                  <input type="text" placeholder="Profile Name" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="my-input-box">
                  <label htmlFor="">Public Email</label>
                  <input type="text" placeholder="Public Email" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="my-input-box">
                  <label htmlFor="">Country</label>
                  <select name="" id="">
                    <option value="" disabled selected>Select Country</option>
                    <option value="">United State</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="my-input-box">
                  <label htmlFor="">City</label>
                  <select name="" id="">
                    <option value="" disabled selected>Select City</option>
                    <option value="">New Work</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="input-info-box mt-30">
          <div className="header">
            Security Info
          </div>
          <div className="content">
            <div className="row">
              <div className="col-md-6">
                <div className="my-input-box">
                  <label htmlFor="">Recovery Email</label>
                  <input type="email" placeholder="Recovery Email" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="my-input-box">
                  <label htmlFor="">Recovery Phone</label>
                  <input type="text" placeholder="Recovery Phone" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="my-input-box">
                  <label htmlFor="">Security Question 01</label>
                  <select name="" id="">
                    <option value="">What is your favorite movie?</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="my-input-box">
                  <label htmlFor="">Answer 01</label>
                  <input type="text" placeholder="Answer 01" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="my-input-box">
                  <label htmlFor="">Security Question 02</label>
                  <select name="" id="">
                    <option value="">In what town was your first job?</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="my-input-box">
                  <label htmlFor="">Answer 02</label>
                  <input type="text" placeholder="Answer 02" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="buttons  mt-30">
          <button type="submit" className="custom-button">Save Changes</button>
          <button className="custom-button2">Discard All</button>
        </div>
      </div>
    </UserSettingCon>
  )
}

export default UserAccountInfo