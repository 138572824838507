import React, { useContext, useEffect, useState } from 'react'
import UserSettingCon from './UserSettingCon'
import { GlobalContext } from '../../../contexts/Provider'
import axiosInstance from '../../../utils/axiosInstance'
import localImage from '../../../assets/avatar.jpeg'
import { setUser } from '../../../contexts/actions/auth'
import ToastComponent from '../../../components/ToastComponent'
import toastInstance from '../../../utils/toast'

const UserSetting = () => {
	const { authDispatch, authState: { user } } = useContext(GlobalContext)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)

	useEffect(() => {
		if (!user.profileImage) {
			alert('To continue with 2Discreet, you have to provide your image showing your face')
		}
	}, [])


	const handleFiles = files => {
		setError(null);
		const form = new FormData();
		console.log('files', files)
		form.append("image", files[0])
		setLoading(true)
		axiosInstance
			.put('/user/image', form)
			.then(res => {
				setLoading(false)
				setUser(res.data.data)(authDispatch)
			}).catch(err => {
				setLoading(false)
				toastInstance('An error occurred while uploading image try again')
				setError(err.response?.data.message)
				console.log(err.response)
			})
	};


	return (
		<UserSettingCon>
			<div className="col-xl-8 col-md-7 ">
				<div className="page-title">
					Profile Info
				</div>
				<div className="row">
					<div className="col-lg-6">
						<div className="profile-about-box">
							<div className="top-bg"></div>
							<div className="p-inner-content">
								<div className="profile-img">
									<img style={{ width: 120, height: 120 }} src={user.profileImage || localImage} alt="" />
									<div className="active-online"></div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="up-photo-card mb-30">
							<div className="icon">
								<i className="fas fa-user"></i>
							</div>
							<div className="content">
								<p style={{ color: 'red', fontSize: 15, textAlign: 'center' }}>{error || null}</p>
								<h4>Upload Profile Image</h4>
								{loading ? 'Uploading...' : <label>
									<input accept='image/*' style={{ display: 'none' }}
										type="file" onChange={(e) => handleFiles(e.target.files)} />
									<i className="fas fa-paperclip" />
								</label>}
								<span> 5MB size maximum</span>
							</div>
						</div>
					</div>
				</div>
				<p style={{ textAlign: 'center', color: 'red' }}>{error}</p>
			</div>
			<ToastComponent />
		</UserSettingCon>
	)
}

export default UserSetting