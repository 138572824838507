import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../../assets/images/logo2.png"

const AuthTopMenuArea = () => {
  return (
    <div className="top-menu-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <Link to="/" className="backto-home"><i className="fas fa-chevron-left"></i> Back to 2Discreet</Link>
          </div>
          <div className="col-lg-7 ">
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthTopMenuArea