import React from 'react'
import { Link } from 'react-router-dom'
import PageWrapper from '../hoc/PageWrapper'

const BlogDetails = () => {
    return (
        <PageWrapper>

            <section className="breadcrumb-area profile-bc-area">
                <div className="container">
                    <div className="content">
                        <h2 className="title extra-padding">
                            Blog
                        </h2>
                        <ul className="breadcrumb-list extra-padding">
                            <li>
                                <Link to="/">
                                    Home
                                </Link>
                            </li>

                            <li>
                                Blog
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="blog-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="single-blog post-details">
                                <div className="img">
                                    <img src="../assets/images/blog/blog1.png" alt="" />
                                </div>
                                <div className="content">
                                    <div className="left">
                                        <div className="avatar">
                                            <img src="../assets/images/blog/author-avarat.png" alt="" />
                                        </div>
                                        <ul className="meta-list">
                                            <li>
                                                <Link to="/">
                                                    <i className="far fa-comments"></i>
                                                </Link>
                                                <span>30</span>
                                            </li>
                                            <li>
                                                <Link to="/">
                                                    <i className="fas fa-share-alt"></i>
                                                </Link>
                                                <span>22</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="right">
                                        <p className="date">
                                            December 19, 2021
                                        </p>
                                        <div className="post-header">
                                            <h4 className="m-title">
                                                Cheap Movie Tickets - Bring Your Loved Ones to See New Releases
                                            </h4>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                                dunt ut labore et dolore magna aliqua.Quis ipsum suspendisse ultrices gravida.
                                                Risus do viverra maecenas accumsan lacus vel facilisis.
                                            </p>
                                            <blockquote>
                                                Modi perferendis ipsa, dolorum eaque accusantium! Velitlibero fugit dolores
                                                repellendus consequatur nisi, deserunt aperiam a ea ex hic, iusto atque, quas.
                                                Aliquam rerum dolores saepe sunt, assumenda voluptas.
                                            </blockquote>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                                dunt ut labore et dolore magna aliqua.Quis ipsum suspendisse ultrices gravida.
                                                Risus do viverra maecenas accumsan lacus vel facilisis.
                                            </p>
                                            <h4 className="title">
                                                Bring Your Loved Ones to See New Releases
                                            </h4>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                                dunt ut labore et dolore magna aliqua.Quis ipsum suspendisse ultrices gravida.
                                                Risus do viverra maecenas accumsan lacus vel facilisis.
                                            </p>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                                dunt ut labore et dolore magna aliqua.Quis ipsum suspendisse ultrices gravida.
                                                Risus do viverra maecenas accumsan lacus vel facilisis.
                                            </p>
                                        </div>
                                        <div className="tags-area">
                                            <div className="tags">
                                                <span>
                                                    Tags :
                                                </span>
                                                <div className="tags-item">
                                                    <Link to="/"> Tickets </Link>
                                                    <Link to="/"> Coming Soon </Link>
                                                    <Link to="/"> Movies </Link>
                                                </div>
                                            </div>
                                            <ul className="social-icons">
                                                <li>
                                                    <Link to="/">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/" className="active">
                                                        <i className="fab fa-twitter"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/">
                                                        <i className="fab fa-instagram"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/">
                                                        <i className="fab fa-pinterest"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/">
                                                        <i className="fab fa-google"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="blog-author">
                                <div className="author-thumb">
                                    <Link to="/">
                                        <img src="../assets/images/blog/blog-admin-big.png" alt="blog" />
                                    </Link>
                                </div>
                                <div className="author-content">
                                    <h5 className="title">
                                        <Link to="/">
                                            Lee Burke
                                        </Link>
                                    </h5>
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dunt ut
                                        labore et dolore magna aliqua.Quis ipsum suspendisse .
                                    </p>
                                </div>
                            </div>
                            <div className="blog-comment">
                                <h5 className="title">comments</h5>
                                <ul className="comment-area">
                                    <li>
                                        <div className="blog-thumb">
                                            <Link to="/">
                                                <img src="../assets/images/blog/comment-1.png" alt="blog" />
                                            </Link>
                                        </div>
                                        <div className="blog-thumb-info">
                                            <span>13 days ago</span>
                                            <h6 className="title"><Link to="/">carl morgan</Link></h6>
                                        </div>
                                        <div className="blog-content">
                                            <p>
                                                Maecenas at velit eu erat egestas vestibulum id ut tellus. Sed et semper mauris.
                                                Quisque eu lorem libero. Donec finibus metus tellus, eget rutrum est mattis non.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="blog-thumb">
                                            <Link to="/">
                                                <img src="../assets/images/blog/comment-1.png" alt="blog" />
                                            </Link>
                                        </div>
                                        <div className="blog-thumb-info">
                                            <span>13 days ago</span>
                                            <h6 className="title"><Link to="/">john flores</Link></h6>
                                        </div>
                                        <div className="blog-content">
                                            <p>
                                                Maecenas at velit eu erat egestas vestibulum id ut tellus. Sed et semper mauris.
                                                Quisque eu lorem libero. Donec finibus metus tellus, eget rutrum est mattis non.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="blog-thumb">
                                            <Link to="/">
                                                <img src="../assets/images/blog/comment-1.png" alt="blog" />
                                            </Link>
                                        </div>
                                        <div className="blog-thumb-info">
                                            <span>13 days ago</span>
                                            <h6 className="title"><Link to="/">carl morgan</Link></h6>
                                        </div>
                                        <div className="blog-content">
                                            <p>
                                                Maecenas at velit eu erat egestas vestibulum id ut tellus. Sed et semper mauris.
                                                Quisque eu lorem libero. Donec finibus metus tellus, eget rutrum est mattis non.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                                <div className="leave-comment">
                                    <h5 className="title">leave comment</h5>
                                    <form className="blog-form">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Your Full Name" required />
                                        </div>
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Enter Your Email Address"
                                                required />
                                        </div>
                                        <div className="form-group">
                                            <textarea placeholder="Write A Message" className="form-control" required></textarea>
                                        </div>
                                        <div className="form-group">
                                            <button className="custom-button" type="submit">Submit Now</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-10 col-md-8">
                            <div className="widget widget-search">
                                <h5 className="title">search</h5>
                                <form className="search-form">
                                    <input type="text" placeholder="Enter your Search Content" required />
                                    <button type="submit"><i className="flaticon-loupe"></i>Search</button>
                                </form>
                            </div>
                            <div className="widget widget-categories">
                                <h5 className="title">categories</h5>
                                <ul>
                                    <li>
                                        <Link to="/">
                                            <span>Showtimes & Tickets</span><span>50</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <span>Latest Trailers</span><span>43</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <span>Coming Soon </span><span>34</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <span>In Theaters</span><span>63</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <span>Release Calendar </span><span>11</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <span>Stars</span><span>30</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <span>Horror Movie </span><span>55</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="widget widget-post">
                                <h5 className="title">latest post</h5>
                                <div className="slider-nav">
                                    <span className="flaticon-angle-pointing-to-left widget-prev"></span>
                                    <span className="flaticon-right-arrow-angle widget-next active"></span>
                                </div>
                                <div className="widget-slider owl-carousel owl-theme">
                                    <div className="item">
                                        <div className="thumb">
                                            <Link to="/">
                                                <img src="../assets/images/blog/resent-post.png" alt="blog" />
                                            </Link>
                                        </div>
                                        <div className="content">
                                            <h6 className="p-title">
                                                <Link to="/">How to Start, Plan, and Keep a
                                                    Date Night</Link>
                                            </h6>
                                            <div className="meta-post">
                                                <Link to="/" className="mr-4"><i className="fas fa-user"></i>Admin</Link>
                                                <Link to="/"> <i className="far fa-comments"></i> Comments</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="thumb">
                                            <Link to="/">
                                                <img src="../assets/images/blog/resent-post.png" alt="blog" />
                                            </Link>
                                        </div>
                                        <div className="content">
                                            <h6 className="p-title">
                                                <Link to="/">How to Start, Plan, and Keep a
                                                    Date Night</Link>
                                            </h6>
                                            <div className="meta-post">
                                                <Link to="/" className="mr-4"><i className="fas fa-user"></i>Admin</Link>
                                                <Link to="/"> <i className="far fa-comments"></i> Comments</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="widget widget-newsletter">
                                <h5 className="title">Newsletter</h5>
                                <form className="search-form">
                                    <input type="text" placeholder="Enter your Email Address" required />
                                    <button type="submit"><i className="far fa-envelope"></i> Send</button>
                                </form>
                            </div>
                            <div className="widget widget-tags">
                                <h5 className="title">featured tags</h5>
                                <ul>
                                    <li>
                                        <Link to="/">creative</Link>
                                    </li>
                                    <li>
                                        <Link to="/">design</Link>
                                    </li>
                                    <li>
                                        <Link to="/">skill</Link>
                                    </li>
                                    <li>
                                        <Link to="/">template</Link>
                                    </li>
                                    <li>
                                        <Link to="/" className="active">landing</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </PageWrapper>
    )
}

export default BlogDetails