import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { GlobalContext } from '../../../../contexts/Provider'

const Topbar = () => {
  const { authState: { user } } = useContext(GlobalContext)
  const { search } = useLocation()

  return (
    <ul className="top-menu">
      <li>
        <Link to={`/user-profile/${user._id}?tab=activity`}
          className={search === '?tab=activity' && 'active'}>
          Activity
        </Link>
      </li>
      <li>
        <Link to={`/user-profile/${user._id}?tab=profile`}
          className={search === '?tab=profile' && 'active'}>
          Profile
        </Link>
      </li>
      <li>
        <Link to={`/user-profile/${user._id}?tab=friends`}
          className={search === '?tab=friends' && 'active'}>
          Friends
        </Link>
      </li>
    </ul>
  )
}

export default Topbar