import React from 'react'
import { Link } from 'react-router-dom'

const ShopCategory = () => {
  return (
    <div className="col-lg-3 product-md-grid">
      <div className="widget-1 widget-range-slider">
        <div className="widget-1-body">
          <h6 className="subtitle">Filter by Price</h6>
          <div className="price-range-slider">
            <div id="slider"></div>
            <div className="p-info">
              <div className="left">
                Price:
              </div>
              <div className="right">
                $0-$1000
              </div>
            </div>
            <Link to="/" className="custom-button">Filter</Link>
          </div>
        </div>
      </div>
      <div className="widget-1 widget-check">
        <div className="widget-1-body">
          <h6 className="subtitle">Gender</h6>
          <div className="check-area">
            <div className="form-group">
              <input type="checkbox" name="mode" id="men" /><label htmlFor="men">Men (30)</label>
            </div>
            <div className="form-group">
              <input type="checkbox" name="mode" id="women" /><label htmlFor="women">Women (25)</label>
            </div>
          </div>
        </div>
      </div>
      <div className="widget-1 widget-check">
        <div className="widget-1-body">
          <h6 className="subtitle">Categories</h6>
          <div className="check-area">
            <div className="form-group">
              <input type="checkbox" name="mode" id="c1" /><label htmlFor="c1">Jewelry &
                Accessories</label>
            </div>
            <div className="form-group">
              <input type="checkbox" name="mode" id="c2" /><label htmlFor="c2">Rings</label>
            </div>
            <div className="form-group">
              <input type="checkbox" name="mode" id="c3" /><label htmlFor="c3">Watches</label>
            </div>
            <div className="form-group">
              <input type="checkbox" name="mode" id="c4" /><label htmlFor="c4">Consume/r
                Electronics</label>
            </div>
            <div className="form-group">
              <input type="checkbox" name="mode" id="c5" /><label htmlFor="c5">Gift item</label>
            </div>
          </div>
        </div>
      </div>
      <div className="widget-1 widget-check">
        <div className="widget-1-body">
          <h6 className="subtitle">Color</h6>
          <div className="check-area">
            <div className="form-group">
              <input type="checkbox" name="mode" id="r1" /><label htmlFor="r1">Blue (11)</label>
            </div>
            <div className="form-group">
              <input type="checkbox" name="mode" id="r2" /><label htmlFor="r2">Black (5)</label>
            </div>
            <div className="form-group">
              <input type="checkbox" name="mode" id="r3" /><label htmlFor="r3">Pink (4)</label>
            </div>
            <div className="form-group">
              <input type="checkbox" name="mode" id="r4" /><label htmlFor="r4">Multi-Coloure/d
                (3)</label>
            </div>
            <div className="form-group">
              <input type="checkbox" name="mode" id="r5" /><label htmlFor="r5">Yellow (2)</label>
            </div>
          </div>
        </div>
      </div>
      <div className="widget-1 widget-check rating">
        <div className="widget-1-body">
          <h6 className="subtitle">Rating</h6>
          <div className="check-area">
            <div className="form-group">
              <input type="checkbox" name="mode" id="s1" /><label htmlFor="s1">
                <span className="text">
                  5 star(9)
                </span>
                <span className="rating">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                </span>
              </label>
            </div>
            <div className="form-group">
              <input type="checkbox" name="mode" id="s2" /><label htmlFor="s2" >
                <span className="text">
                  4 star(9)
                </span>
                <span className="rating">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="far fa-star"></i>
                </span>
              </label>
            </div>
            <div className="form-group">
              <input type="checkbox" name="mode" id="s3" /><label htmlFor="s3" >
                <span className="text">
                  3 star(9)
                </span>
                <span className="rating">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="far fa-star"></i>
                  <i className="far fa-star"></i>
                </span>
              </label>
            </div>
            <div className="form-group">
              <input type="checkbox" name="mode" id="s4" /><label htmlFor="s4" >
                <span className="text">
                  2 star(9)
                </span>
                <span className="rating">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="far fa-star"></i>
                  <i className="far fa-star"></i>
                  <i className="far fa-star"></i>
                </span>
              </label>
            </div>
            <div className="form-group">
              <input type="checkbox" name="mode" id="s5" /><label htmlFor="s5" >
                <span className="text">
                  1 star(9)
                </span>
                <span className="rating">
                  <i className="fas fa-star"></i>
                  <i className="far fa-star"></i>
                  <i className="far fa-star"></i>
                  <i className="far fa-star"></i>
                  <i className="far fa-star"></i>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShopCategory