import React from 'react'

const Preloader = ({ text }) => {
  return (
    <div className="preloader-inner">
      <div className="preloader-icon">
        <span></span>
        <span></span>
      </div>
    </div>
  )
}

export default Preloader