import React from 'react'

const ShopFilter = () => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="filter-area">
          <div className="filter-main">
            <ul className="nav grid-button tab-menu" role="tablist">
              <li id="gird-view" className="gird-view">
                <a className="active" id="pills-gird-tab" data-toggle="pill" href="#pills-gird"
                  role="tab" aria-controls="pills-gird" aria-selected="true">
                  <i className="fas fa-th"></i>
                </a>
              </li>
              <li id="list-view" className="list-view">
                <a className="" id="pills-list-tab" data-toggle="pill" href="#pills-list"
                  role="tab" aria-controls="pills-list" aria-selected="false">
                  <i className="fas fa-bars"></i>
                </a>

              </li>
            </ul>
            <div className="right">
              <div className="item">
                <span className="show">Show :</span>
                <select className="select-bar">
                  <option value="12">12</option>
                  <option value="15">15</option>
                  <option value="18">18</option>
                  <option value="21">21</option>
                  <option value="24">24</option>
                  <option value="27">27</option>
                  <option value="30">30</option>
                </select>
              </div>
              <div className="item">
                <span className="show">Sort By :</span>
                <select className="select-bar">
                  <option value="showing">New</option>
                  <option value="exclusive">exclusive</option>
                  <option value="trending">trending</option>
                  <option value="most-view">most view</option>
                </select>
              </div>
              <div className="serch-icon">
                <i className="fas fa-search"></i>
              </div>
            </div>
          </div>

          <div className="search-box">
            <input type="text" placeholder="Enter Your Text hear" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShopFilter