import React from 'react'
import { Link } from 'react-router-dom'
import PageWrapper from '../hoc/PageWrapper'

const Blog = () => {
  return (
    <PageWrapper>
      <section className="breadcrumb-area profile-bc-area">
        <div className="container">
          <div className="content">
            <h2 className="title extra-padding">
              Blog
            </h2>
            <ul className="breadcrumb-list extra-padding">
              <li>
                <Link to="/">
                  Home
                </Link>
              </li>

              <li>
                Blog
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="blog-page single-blog-page">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-12">
                  <div className="single-blog">
                    <div className="img">
                      <img src="../assets/images/blog/blog1.png" alt="" />
                    </div>
                    <div className="content">
                      <div className="left">
                        <div className="avatar">
                          <img src="../assets/images/blog/author-avarat.png" alt="" />
                        </div>
                        <ul className="meta-list">
                          <li>
                            <Link to="/">
                              <i className="far fa-comments"></i>
                            </Link>
                            <span>30</span>
                          </li>
                          <li>
                            <Link to="/">
                              <i className="fas fa-share-alt"></i>
                            </Link>
                            <span>22</span>
                          </li>
                        </ul>
                      </div>
                      <div className="right">
                        <p className="date">
                          December 19, 2021
                        </p>
                        <h4 className="title">
                          Tips You Should Know When You Plan To
                          Date Online
                        </h4>
                        <p className="text">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                          tempor dunt ut labore et dolore magna aliqua.Quis ipsum suspendisse ultrices
                          gravida. Risus do viverra maecenas
                        </p>
                      </div>
                    </div>
                    <div className="post-footer">
                      <div className="left">
                        <p>
                          <b>Category:</b> Dating Advice
                        </p>
                      </div>
                      <div className="right">
                        <Link to="/" className="read-more-btn">Continue Reading</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="single-blog">
                    <div className="img">
                      <img src="../assets/images/blog/blog2.png" alt="" />
                    </div>
                    <div className="content">
                      <div className="left">
                        <div className="avatar">
                          <img src="../assets/images/blog/author-avarat.png" alt="" />
                        </div>
                        <ul className="meta-list">
                          <li>
                            <Link to="/">
                              <i className="far fa-comments"></i>
                            </Link>
                            <span>30</span>
                          </li>
                          <li>
                            <Link to="/">
                              <i className="fas fa-share-alt"></i>
                            </Link>
                            <span>22</span>
                          </li>
                        </ul>
                      </div>
                      <div className="right">
                        <p className="date">
                          December 19, 2021
                        </p>
                        <h4 className="title">
                          Want to Be Successful at Friends Dating? - Top
                          Online Dating Tips and Techniques
                        </h4>
                        <p className="text">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                          tempor dunt ut labore et dolore magna aliqua.Quis ipsum suspendisse ultrices
                          gravida. Risus do viverra maecenas
                        </p>
                      </div>
                    </div>
                    <div className="post-footer">
                      <div className="left">
                        <p>
                          <b>Category:</b> Dating Advice
                        </p>
                      </div>
                      <div className="right">
                        <Link to="/" className="read-more-btn">Continue Reading</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="single-blog">
                    <div className="img">
                      <img src="../assets/images/blog/blog3.png" alt="" />
                    </div>
                    <div className="content">
                      <div className="left">
                        <div className="avatar">
                          <img src="../assets/images/blog/author-avarat.png" alt="" />
                        </div>
                        <ul className="meta-list">
                          <li>
                            <Link to="/">
                              <i className="far fa-comments"></i>
                            </Link>
                            <span>30</span>
                          </li>
                          <li>
                            <Link to="/">
                              <i className="fas fa-share-alt"></i>
                            </Link>
                            <span>22</span>
                          </li>
                        </ul>
                      </div>
                      <div className="right">
                        <p className="date">
                          December 19, 2021
                        </p>
                        <h4 className="title">
                          Welcome the Dating Coach - The New Super
                          Hero of 21st Century Online Dating
                        </h4>
                        <p className="text">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                          tempor dunt ut labore et dolore magna aliqua.Quis ipsum suspendisse ultrices
                          gravida. Risus do viverra maecenas
                        </p>
                      </div>
                    </div>
                    <div className="post-footer">
                      <div className="left">
                        <p>
                          <b>Category:</b> Dating Advice
                        </p>
                      </div>
                      <div className="right">
                        <Link to="/" className="read-more-btn">Continue Reading</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="single-blog">
                    <div className="img">
                      <img src="../assets/images/blog/blog4.png" alt="" />
                    </div>
                    <div className="content">
                      <div className="left">
                        <div className="avatar">
                          <img src="../assets/images/blog/author-avarat.png" alt="" />
                        </div>
                        <ul className="meta-list">
                          <li>
                            <Link to="/">
                              <i className="far fa-comments"></i>
                            </Link>
                            <span>30</span>
                          </li>
                          <li>
                            <Link to="/">
                              <i className="fas fa-share-alt"></i>
                            </Link>
                            <span>22</span>
                          </li>
                        </ul>
                      </div>
                      <div className="right">
                        <p className="date">
                          December 19, 2021
                        </p>
                        <h4 className="title">
                          Tips You Should Know When You Plan To
                          Date Online
                        </h4>
                        <p className="text">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                          tempor dunt ut labore et dolore magna aliqua.Quis ipsum suspendisse ultrices
                          gravida. Risus do viverra maecenas
                        </p>
                      </div>
                    </div>
                    <div className="post-footer">
                      <div className="left">
                        <p>
                          <b>Category:</b> Dating Advice
                        </p>
                      </div>
                      <div className="right">
                        <Link to="/" className="read-more-btn">Continue Reading</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="widget widget-search">
                <h5 className="title">search</h5>
                <form className="search-form">
                  <input type="text" placeholder="Enter your Search Content" required />
                  <button type="submit"><i className="flaticon-loupe"></i>Search</button>
                </form>
              </div>
              <div className="widget widget-categories">
                <h5 className="title">categories</h5>
                <ul>
                  <li>
                    <Link to="/">
                      <span>Showtimes & Tickets</span><span>50</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <span>Latest Trailers</span><span>43</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <span>Coming Soon </span><span>34</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <span>In Theaters</span><span>63</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <span>Release Calendar </span><span>11</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <span>Stars</span><span>30</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <span>Horror Movie </span><span>55</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="widget widget-post">
                <h5 className="title">latest post</h5>
                <div className="slider-nav">
                  <span className="flaticon-angle-pointing-to-left widget-prev"></span>
                  <span className="flaticon-right-arrow-angle widget-next active"></span>
                </div>
                <div className="widget-slider owl-carousel owl-theme">
                  <div className="item">
                    <div className="thumb">
                      <Link to="/">
                        <img src="../assets/images/blog/resent-post.png" alt="blog" />
                      </Link>
                    </div>
                    <div className="content">
                      <h6 className="p-title">
                        <Link to="/">How to Start, Plan, and Keep a
                          Date Night</Link>
                      </h6>
                      <div className="meta-post">
                        <Link to="/" className="mr-4"><i className="fas fa-user"></i>Admin</Link>
                        <Link to="/"> <i className="far fa-comments"></i> Comments</Link>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="thumb">
                      <Link to="/">
                        <img src="../assets/images/blog/resent-post.png" alt="blog" />
                      </Link>
                    </div>
                    <div className="content">
                      <h6 className="p-title">
                        <Link to="/">How to Start, Plan, and Keep a
                          Date Night</Link>
                      </h6>
                      <div className="meta-post">
                        <Link to="/" className="mr-4"><i className="fas fa-user"></i>Admin</Link>
                        <Link to="/"> <i className="far fa-comments"></i> Comments</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="widget widget-newsletter">
                <h5 className="title">Newsletter</h5>
                <form className="search-form">
                  <input type="text" placeholder="Enter your Email Address" required />
                  <button type="submit"><i className="far fa-envelope"></i> Send</button>
                </form>
              </div>
              <div className="widget widget-tags">
                <h5 className="title">featured tags</h5>
                <ul>
                  <li>
                    <Link to="/">creative</Link>
                  </li>
                  <li>
                    <Link to="/">design</Link>
                  </li>
                  <li>
                    <Link to="/">skill</Link>
                  </li>
                  <li>
                    <Link to="/">template</Link>
                  </li>
                  <li>
                    <Link to="/" className="active">landing</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>


    </PageWrapper>
  )
}

export default Blog