import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { loginUser } from '../../contexts/actions/auth'
import { GlobalContext } from '../../contexts/Provider'
import './styles.css'
import banner from '../../assets/images/logo.png'
import global from '../../assets/images/global.png'

function LandingPage() {
  const { authDispatch, authState } = useContext(GlobalContext)
  const [form, setForm] = useState({
    username: { body: '', error: null },
    password: { body: '', error: null }
  })
  const navigate = useNavigate()

  useEffect(() => {
    localStorage.removeItem('accessToken')
  }, [])

  useEffect(() => {
    if (authState.isAuthenticated) navigate('/community')
    // return () => setForm({
    //   username: { body: '', error: null },
    //   password: { body: '', error: null }
    // })
  }, [authState.isAuthenticated])

  const handleFormInput = (value) => {
    setForm({ ...form, ...value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!form.password.body || !form.username.body) {
      if (!form.username.body) setForm(form => ({ ...form, username: { body: '', error: 'username is required' } }))
      if (!form.password.body) setForm(form => ({ ...form, password: { body: '', error: 'password is required' } }))
    }
    else {
      const data = {
        username: form.username.body,
        password: form.password.body,
      }
      loginUser(data)(authDispatch)
    }
  }


  return (
    <div className="LandingPage">
      <div className="bg_image">
        <div className='login_side'>
          <span className='nav_item'>
            <Link style={{ color: 'white', textDecoration: 'none' }} to='/register'>SIGN UP</Link>
          </span>
          <div className='swap_banner'>
            <img className='landing_logo' src={banner} alt='banner' />
            <p className='motto'>Where <span style={{ color: '#f00' }}> SWINGERS</span> come out to PLAY</p>
          </div>
          <div className='input_container'>
            <label>UserName</label>
            <input onChange={(e) => handleFormInput({ username: { body: e.target.value, error: null } })}
              type="text" name='username' value={form.username.body}
              style={{ borderColor: form.username.error && 'red' }}
              className='register_input' placeholder="Enter Your Username" />
            <div style={{ marginBottom: '5px' }}>Password</div>
            <input type="password" className='register_input' style={{ borderColor: form.password.error && 'red' }}
              onChange={(e) => handleFormInput({ password: { body: e.target.value, error: null } })}
              placeholder="Enter Your Password" value={form.password.body} />
          </div>
          <div className='register_button'>
            <p style={{ textAlign: 'center', color: 'red' }}>{authState.networkError || null}</p>
            <button onClick={handleSubmit}>
              {authState.networkLoading ? 'Loading...' : 'LOGIN NOW!'}
            </button>
            {
              authState.networkError === 'Kindly verify your account' &&
              <p style={{ textAlign: 'center' }}>
                Verify your account <Link to='/verify-user'> here</Link>
              </p>
            }
          </div>
        </div>
      </div>

      <div className='meet_div'>
        <p>Meet The Hottest Members In <span style={{ color: '#222' }}> New York</span></p>
        <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img alt='global' src={global} style={{ marginRight: '5px' }} /> 61,478 Members Online NOW!
        </p>
      </div>

      <div className='flex_container'>
        <div className='first_row'>
          <div className='fun_image1' />
          <div className='details'>
            <h2>The world's hottest swinger site</h2>
            <p>
              2discreet.com is where sexy, adventurous couples come together for sex, friendship,
              and good times with like-minded people. Chat live with sexy singles and swingers using our Instant Messenger
              Chat (IMC), exchange photos and videos, and get up-close and personal on two-way webcam.
              Join now for free and see how easy it is to meet and mingle on the world's hottest couple-swapping site!
            </p>
            <Link to='/register'><button>CREATE A FREE PROFILE</button></Link>
          </div>
        </div>
        <div className='second_row'>
          <div className='fun_image2' />
          <div className='details'>
            <h2>What makes 2discreet different?</h2>
            <div>
              2discreet.com has millions of members worldwide, so you're sure to find someone in your area
              looking for the same things you are. We also offer a ton of great features designed to help you get to
              know each other before you decide to meet up in person.
              <ul>
                <li>Browse detailed member profiles</li>
                <li>Find swingers in your area</li>
                <li>Enjoy sex chat via Instant Messenger or two-way webcam</li>
                <li>View thousands of nude member photos and videos</li>
                <li>Be part of an international, sex-positive community of swingers</li>
              </ul>
            </div>
            <Link to='/register'><button>CREATE A FREE PROFILE</button></Link>
          </div>
        </div>
      </div>

    </div>
  );
}

export default LandingPage;
