import React from 'react'
import LoginForm from './sections/LoginForm'
import AuthTopMenuArea from '../../components/Auth/AuthTopMenuArea'

const Login = () => {
  return (
    <div>
      <section className="log-reg">
        <AuthTopMenuArea />
        <div className="container">
          <div className="row justify-content-end">
            <div className="image image-log">
            </div>
            <div className="col-lg-7">
              <div className="log-reg-inner">
                <div className="section-header inloginp">
                  <h2 className="title">
                    Welcome to 2Discreet
                  </h2>
                </div>
                <LoginForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  )
}

export default Login