import React, { useContext, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import Preloader from '../components/Preloader'
import { GlobalContext } from '../contexts/Provider'
import { setUser } from '../contexts/actions/auth'
import axiosInstance from '../utils/axiosInstance'

const Auth = ({ children }) => {
  const { authState, authDispatch } = useContext(GlobalContext)
  const [loading, setLoading] = useState(true)
  const accessToken = localStorage.getItem('accessToken')

  useEffect(() => {
    if (!authState.isAuthenticated && accessToken) {
      setLoading(true)
      axiosInstance.get('/user/get-user')
        .then(res => {
          if (res.data.data) {
            setLoading(val => {
              setUser(res.data.data)(authDispatch)
              return false
            })
          }
        }).catch(err => setLoading(false))
    } else setLoading(false)
  }, [])

  return (
    <>
      {loading ?
        <Preloader />
        : <>{authState.isAuthenticated ?
          <>
            {authState.user.verified ?
              // <>
              //   {authState.user.profileImage ?
              <>
                {children}
              </>
              //     : <Navigate to='/user-setting' />}
              // </>
              : <Navigate to='/verify-user' />}
          </>
          : <Navigate to='/login' />}
        </>}
    </>
  )
}

export default Auth