import React, { useContext, useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import Preloader from '../../../components/Preloader'
import Topic from '../../../components/Topic'
import WritePost from '../../../components/WritePost'
import { GlobalContext } from '../../../contexts/Provider'
import axiosInstance from '../../../utils/axiosInstance'
import MainProfileCon from './sections/MainProfileCon'
import Profile from './sections/Profile'
import Friends from './sections/Friends'
import toastInstance from '../../../utils/toast'
import ToastComponent from '../../../components/ToastComponent'

const SingleProfile = () => {
  const { authState: { user } } = useContext(GlobalContext)
  const [userPosts, setUserPosts] = useState([])
  const [text, setText] = useState('')
  const [images, setImages] = useState(null)
  const [error, setError] = useState(null)
  const [postLoading, setPostLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [searchParam] = useSearchParams()
  const newQuery = searchParam.get('tab')

  useEffect(() => {
    setQuery(newQuery)
  }, [newQuery])

  useEffect(() => {
    setLoading(true)
    axiosInstance.get('/post/user?sort=-createdAt')
      .then(res => {
        setLoading(false)
        if (res.data.data) {
          setUserPosts(res.data.data)
        }
      }).catch(err => {
        setLoading(false)
        toastInstance("Error occurred while getting user's posts", true)
      })
  }, [])

  const handleSubmit = () => {
    const formData = new FormData()
    if (text) formData.append('content', text)
    else return setError('Enter a text')
    setPostLoading(true)
    if (images) formData.append('images', images)
    axiosInstance.post('/post', formData)
      .then(res => {
        setPostLoading(false)
        setText('')
        addPost(res.data.data)
        toastInstance("Your post has been created")
      }).catch(err => {
        setPostLoading(false)
        toastInstance('An error occurred while creating your post', true)
      })
  }

  const addPost = (newPost) => {
    const newPosts = [newPost, ...userPosts]
    setUserPosts(newPosts)
  }

  const handleFileChange = (files) => {
    setImages(files)
  }

  const handleTextChange = e => {
    setText(e.target.value)
    setError(null)
  }

  const filterData = (id) => {
    const newPosts = userPosts.filter(post => post._id !== id)
    setUserPosts(newPosts)
  }

  const handleDeletePost = (postId) => {
    axiosInstance.delete(`/post/${postId}`)
      .then(res => {
        if (res.data.statusCode === 200) {
          filterData(res.data.data._id)
        }
        toastInstance("Your post has been deleted")
      }).catch(err => {
        toastInstance('Post not deleted', true)
      })
  }

  const handleLike = (id) => {
    axiosInstance.patch(`/post/like/${id}`)
      .then(res => {
        const copyPosts = [...userPosts]
        const createdPost = copyPosts.find(post => post._id === res.data.data._id)
        createdPost.likes = res.data.data.likes
        setUserPosts(copyPosts)
        console.log('res.data', res.data)
      }).catch(err => {
        toastInstance('An error occurred while liking post', true)
      })
  }

  return (
    <MainProfileCon>
      <>{query === 'activity' &&
        <>
          {!loading ?
            <>
              <WritePost
                user={user} handleSubmit={handleSubmit} error={error}
                postLoading={postLoading} handleFileChange={handleFileChange}
                handleTextChange={handleTextChange} text={text} images={images} />
              {userPosts.map((post, i) => (
                <Topic openComment={false} key={i}
                  handleLike={handleLike} post={post} handleDelete={handleDeletePost} />
              ))}
              {/* <Link to="/" className="load-more">Load More..</Link> */}
            </> :
            <Preloader />}
        </>}
      </>
      <>{query === 'profile' && <Profile />}
      </>
      <>{query === 'friends' && <Friends />}
      </>
      <ToastComponent />
    </MainProfileCon>
  )
}

export default SingleProfile