import React, { useEffect } from 'react'
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import SearchOverlay from '../components/SearchOverlay';
import ScrollToTop from '../components/ScrollToTop';
import query from '../jquery'

const PageWrapper = ({ children }) => {

  useEffect(() => {
    query()
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <ScrollToTop />
      <Navbar />
      <SearchOverlay />
      {children}
      <Footer />
    </div>
  )
}

export default PageWrapper