import React, { useContext } from 'react'
import { GlobalContext } from '../../../../contexts/Provider'

const Profile = () => {
  const { authState: { user } } = useContext(GlobalContext)

  return (
    <>
      <div className="info-box">
        <div className="header"> <h4 className="title"> Base </h4> </div>
        <div className="content">
          <ul className="infolist">
            <li><span>Name</span><span>{`${user.firstname} ${user.lastname}`}</span></li>
            <li><span>Username</span><span>{user.username}</span></li>
            <li><span>Birthday</span><span>{user.dob}</span></li>
            <li><span>I am a</span><span>{user.gender}</span></li>
            <li><span>Looking for a</span><span>{user.lookingFor}</span></li>
            <li><span>Marital status</span><span>{user.maritalStatus}</span></li>
            <li><span>Country</span><span>{user.country}</span></li>
            <li><span>City</span><span>{user.city}</span></li>
          </ul>
        </div>
      </div>
      <div className="info-box">
        <div className="header"><h4 className="title">Myself Summary</h4></div>
        <div className="content"><p className="text">{user.bio}</p></div>
      </div>
      <div className="info-box">
        <div className="header"><h4 className="title">Lifestyle</h4></div>
        <div className="content">
          <ul className="infolist">
            <li><span>Interests</span><span>-</span></li>
            <li><span>Looking for</span><span>-</span></li>
            <li><span>Smoking</span><span>-</span></li>
            <li><span>Language</span><span>-</span></li>
          </ul>
        </div>
      </div>
      <div className="info-box">
        <div className="header"><h4 className="title">Physical</h4></div>
        <div className="content">
          <ul className="infolist">
            <li><span>Height</span><span>-0</span></li>
            <li><span>Weight</span><span>-</span></li>
            <li><span>Hair Color</span><span>-</span></li>
            <li><span>Eye Color</span><span>-</span></li>
            <li><span>Body Type</span><span>-</span></li>
            <li><span>Ethnicity</span><span>-</span></li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Profile