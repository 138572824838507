import React from 'react'
import { Link } from 'react-router-dom'

const BreadcrumbHeader = ({ header, links }) => {
  return (
    <section className="breadcrumb-area profile-bc-area">
      <div className="container">
        <div className="content">
          <h2 className="title extra-padding">
            {header}
          </h2>
          <ul className="breadcrumb-list extra-padding">
            {links.map(link => (
              <li>
                {link}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default BreadcrumbHeader