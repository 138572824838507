import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import BreadcrumbHeader from '../../../components/BreadcrumbHeader'
import Preloader from '../../../components/Preloader'
import { GlobalContext } from '../../../contexts/Provider'
import PageWrapper from '../../../hoc/PageWrapper'
import axiosInstance from '../../../utils/axiosInstance'
import ProfileNotfound from '../ProfileNotfound'
import ProfileSection from './sections/ProfileSection'
import MyProfile from './MyProfile'
import toastInstance from '../../../utils/toast'
import ToastComponent from '../../../components/ToastComponent'

const UserProfile = () => {
  const { authState } = useContext(GlobalContext)
  const { id } = useParams()
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    axiosInstance.get(`/user/${id}`)
      .then(res => {
        setUser(res.data.data)
        console.log('res.data.data', res.data.data)
        console.log('authState.user', authState.user)
        setLoading(false)
      }).catch(err => {
        setLoading(false)
        toastInstance("Error occurred while getting user", true)
      })
  }, [id])

  return (
    <>{!loading ?
      <>{!user ? <ProfileNotfound /> :
        <>{user._id === authState.user._id ?
          <MyProfile /> :
          <PageWrapper>
            <BreadcrumbHeader header='Profile' links={[<Link to='/'>Home</Link>, "Profile"]} />
            <section className="profile-section">
              <div className="container">
                <div className="row justify-content-center">
                  <ProfileSection user={user} otherUser={true} />
                  <div className="col-xl-5 col-lg-6">
                    <div className="profile-main-content">
                      <div className="info-box">
                        <div className="header"> <h4 className="title"> Base </h4> </div>
                        <div className="content">
                          <ul className="infolist">
                            <li><span>Name</span><span>{`${user.firstname} ${user.lastname}`}</span></li>
                            <li><span>Username</span><span>{user.username}</span></li>
                            <li><span>Birthday</span><span>{user.dob}</span></li>
                            <li><span>I am a</span><span>{user.gender}</span></li>
                            <li><span>Looking for a</span><span>{user.lookingFor}</span></li>
                            <li><span>Marital status</span><span>{user.maritalStatus}</span></li>
                            <li><span>Country</span><span>{user.country}</span></li>
                            <li><span>City</span><span>{user.city}</span></li>
                          </ul>
                        </div>
                      </div>
                      <div className="info-box">
                        <div className="header"><h4 className="title">User's Bio</h4></div>
                        <div className="content"><p className="text">{user.bio}</p></div>
                      </div>
                      <div className="info-box">
                        <div className="header"><h4 className="title">Lifestyle</h4></div>
                        <div className="content">
                          <ul className="infolist">
                            <li><span>Interests</span><span>-</span></li>
                            <li><span>Looking for</span><span>-</span></li>
                            <li><span>Smoking</span><span>-</span></li>
                            <li><span>Language</span><span>-</span></li>
                          </ul>
                        </div>
                      </div>
                      <div className="info-box">
                        <div className="header"><h4 className="title">Physical</h4></div>
                        <div className="content">
                          <ul className="infolist">
                            <li><span>Height</span><span>-0</span></li>
                            <li><span>Weight</span><span>-</span></li>
                            <li><span>Hair Color</span><span>-</span></li>
                            <li><span>Eye Color</span><span>-</span></li>
                            <li><span>Body Type</span><span>-</span></li>
                            <li><span>Ethnicity</span><span>-</span></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <PeopleYouMayKnow /> */}
                </div>
              </div>
            </section>
          </PageWrapper>
        }</>}</>
      : <Preloader />}
      <ToastComponent />
    </>
  )
}

export default UserProfile