import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GlobalContext } from '../../../contexts/Provider'
import { registerUser } from '../../../contexts/actions/auth'
import { Link } from 'react-router-dom'
import { AllCountries } from './AllCountries'

const RegForm = () => {
  const { authDispatch, authState } = useContext(GlobalContext)
  const navigate = useNavigate()
  const [error, setError] = useState(null)
  const [form, setForm] = useState({
    username: { body: '', error: null },
    email: { body: '', error: null },
    firstname: { body: '', error: null },
    lastname: { body: '', error: null },
    password: { body: '', error: null },
    confirmPassword: { body: '', error: null },
    bio: { body: '', error: null },
    city: { body: '', error: null },
    country: { body: 'Afghanistan', error: null },
    lookingFor: { body: '', error: null },
    iAm: { body: '', error: null },
    birthday: { body: '', error: null },
    maritalStatus: { body: 'single', error: null }
  })

  useEffect(() => {
    localStorage.removeItem('accessToken')
  }, [])

  useEffect(() => {
    if (authState.isAuthenticated) navigate('/verify-user')
    // return () => setForm({
    //   username: { body: '', error: null },
    //   email: { body: '', error: null },
    //   firstname: { body: '', error: null },
    //   lastname: { body: '', error: null },
    //   password: { body: '', error: null },
    //   confirmPassword: { body: '', error: null },
    //   bio: { body: '', error: null },
    //   city: { body: '', error: null },
    //   country: { body: '', error: null },
    //   lookingFor: { body: '', error: null },
    //   iAm: { body: '', error: null },
    //   birthday: { body: '', error: null },
    //   maritalStatus: { body: '', error: null }
    // })
  }, [authState.isAuthenticated])


  const handleFormInput = (value) => {
    setError(null)
    setForm({ ...form, ...value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!form.username.body || !form.email.body || !form.firstname.body || !form.lastname.body || !form.password.body
      || !form.confirmPassword.body || !form.bio.body || !form.city.body || !form.country.body ||
      !form.lookingFor.body || !form.iAm.body || !form.birthday.body || !form.maritalStatus.body) {
      setError('Check all your inputs, probably forgot some')
      if (!form.username.body) setForm(form => ({ ...form, username: { body: '', error: 'username is required' } }))
      if (!form.email.body) setForm(form => ({ ...form, email: { body: '', error: 'email is required' } }))
      if (!form.firstname.body) setForm(form => ({ ...form, firstname: { body: '', error: 'First last is required' } }))
      if (!form.lastname.body) setForm(form => ({ ...form, lastname: { body: '', error: 'Last last is required' } }))
      if (!form.password.body) setForm(form => ({ ...form, password: { body: '', error: 'password is required' } }))
      if (!form.confirmPassword.body) setForm(form => ({ ...form, confirmPassword: { body: '', error: 'confirm password is required' } }))
      if (!form.bio.body) setForm(form => ({ ...form, bio: { body: '', error: 'bio is required' } }))
      if (!form.city.body) setForm(form => ({ ...form, city: { body: '', error: 'city is required' } }))
      if (!form.country.body) setForm(form => ({ ...form, country: { body: '', error: 'country is required' } }))
      if (!form.lookingFor.body) setForm(form => ({ ...form, lookingFor: { body: '', error: 'looking for is required' } }))
      if (!form.iAm.body) setForm(form => ({ ...form, iAm: { body: '', error: 'i am is required' } }))
      if (!form.birthday.body) setForm(form => ({ ...form, birthday: { body: '', error: 'birthday is required' } }))
      if (!form.maritalStatus.body) setForm(form => ({ ...form, maritalStatus: { body: '', error: 'maritalStatus is required' } }))
      if (form.password.body !== form.confirmPassword.body) setForm(form => ({ ...form, confirmPassword: { ...form.confirmPassword, error: 'passwords do not match' } }))
    } else {
      const data = {
        username: form.username.body,
        email: form.email.body,
        firstname: form.firstname.body,
        lastname: form.lastname.body,
        password: form.password.body,
        confirmPassword: form.confirmPassword.body,
        bio: form.bio.body,
        city: form.city.body,
        country: form.country.body,
        lookingFor: form.lookingFor.body,
        gender: form.iAm.body,
        dob: form.birthday.body,
        maritalStatus: form.maritalStatus.body,
      }
      registerUser(data)(authDispatch)
    }
  }

  return (
    <div className="main-content">
      <form onSubmit={handleSubmit}>
        <h4 className="content-title">Acount Details</h4>
        <div className="form-group">
          <label htmlFor="">Username*</label>
          <p style={{ fontSize: '12px', color: 'red', textAlign: 'center', marginBottom: 0 }}>{form.username.error || null}</p>
          <input style={{ borderColor: form.username.error && 'red' }} type="text" className="my-form-control"
            onChange={(e) => handleFormInput({ username: { body: e.target.value, error: null } })}
            placeholder="Enter Your Username" value={form.username.body} />
        </div>
        <div className="form-group">
          <label htmlFor="">Email Address*</label>
          <p style={{ fontSize: '12px', color: 'red', textAlign: 'center', marginBottom: 0 }}>{form.email.error || null}</p>
          <input style={{ borderColor: form.email.error && 'red' }} type="email" className="my-form-control"
            onChange={(e) => handleFormInput({ email: { body: e.target.value, error: null } })}
            placeholder="Enter Your Email" value={form.email.body} />
        </div>
        <div className="form-group">
          <label htmlFor="">Password*</label>
          <p style={{ fontSize: '12px', color: 'red', textAlign: 'center', marginBottom: 0 }}>{form.password.error || null}</p>
          <input style={{ borderColor: form.password.error && 'red' }} type="password" className="my-form-control"
            onChange={(e) => handleFormInput({ password: { body: e.target.value, error: null } })}
            placeholder="Enter Your Password" value={form.password.body} />
        </div>
        <div className="form-group">
          <label htmlFor="">Confirm Password*</label>
          <p style={{ fontSize: '12px', color: 'red', textAlign: 'center', marginBottom: 0 }}>{form.confirmPassword.error || null}</p>
          <input style={{ borderColor: form.confirmPassword.error && 'red' }} type="password" className="my-form-control"
            onChange={(e) => handleFormInput({ confirmPassword: { body: e.target.value, error: null } })}
            placeholder="Enter Your Password" value={form.confirmPassword.body} />
        </div>
        <h4 className="content-title mt-5">Profile Details</h4>
        <div className="form-group">
          <label htmlFor="">First Name*</label>
          <p style={{ fontSize: '12px', color: 'red', textAlign: 'center', marginBottom: 0 }}>{form.firstname.error || null}</p>
          <input style={{ borderColor: form.firstname.error && 'red' }} type="text" className="my-form-control"
            onChange={(e) => handleFormInput({ firstname: { body: e.target.value, error: null } })}
            placeholder="Enter Your First Name" value={form.firstname.body} />
        </div>
        <div className="form-group">
          <label htmlFor="">Last Name*</label>
          <p style={{ fontSize: '12px', color: 'red', textAlign: 'center', marginBottom: 0 }}>{form.lastname.error || null}</p>
          <input style={{ borderColor: form.lastname.error && 'red' }} type="text" className="my-form-control"
            onChange={(e) => handleFormInput({ lastname: { body: e.target.value, error: null } })}
            placeholder="Enter Your Last Name" value={form.lastname.body} />
        </div>
        <div className="form-group">
          <label htmlFor="">Birthday*</label>
          <p style={{ fontSize: '12px', color: 'red', textAlign: 'center', marginBottom: 0 }}>{form.birthday.error || null}</p>
          <input style={{ borderColor: form.birthday.error && 'red' }} type="date" className="my-form-control"
            onChange={(e) => handleFormInput({ birthday: { body: e.target.value, error: null } })}
            value={form.birthday.body}
          />
        </div>
        <div className="form-group">
          <label htmlFor="">Gender*</label>
          <p style={{ fontSize: '12px', color: 'red', textAlign: 'center', marginBottom: 0 }}>{form.iAm.error || null}</p>
          <div style={{ borderColor: form.iAm.error && 'red' }} className="option">
            <div className="s-input mr-3">
              <input onClick={(e) => handleFormInput({ iAm: { body: e.target.value, error: null } })}
                type="radio" last="gender1" id="males1" value='male' /><label htmlFor="males1">Male</label>
            </div>
            <div className="s-input">
              <input onClick={(e) => handleFormInput({ iAm: { body: e.target.value, error: null } })}
                type="radio" last="gender1" id="females1" value='female' /><label htmlFor="females1">Female</label>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="">Looking for a*</label>
          <p style={{ fontSize: '12px', color: 'red', textAlign: 'center', marginBottom: 0 }}>{form.lookingFor.error || null}</p>
          <div style={{ borderColor: form.lookingFor.error && 'red' }} className="option">
            <div className="s-input mr-3">
              <input onClick={(e) => handleFormInput({ lookingFor: { body: e.target.value, error: null } })}
                type="radio" last="gender2" id="males" value='Man' />
              <label htmlFor="males">Man</label>
            </div>
            <div className="s-input">
              <input onClick={(e) => handleFormInput({ lookingFor: { body: e.target.value, error: null } })}
                type="radio" last="gender2" id="females" value='Woman' />
              <label htmlFor="females">Woman</label>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="">City*</label>
          <p style={{ fontSize: '12px', color: 'red', textAlign: 'center', marginBottom: 0 }}>{form.city.error || null}</p>
          <input style={{ borderColor: form.city.error && 'red' }} type="text" className="my-form-control"
            onChange={(e) => handleFormInput({ city: { body: e.target.value, error: null } })}
            placeholder="Enter Your City" value={form.city.body} />
        </div>
        <div className="form-group">
          <label htmlFor="">Country*</label>
          <p style={{ fontSize: '12px', color: 'red', textAlign: 'center', marginBottom: 0 }}>{form.country.error || null}</p>
          <div style={{ borderColor: form.country.error && 'red' }} className="option">
            <div className="s-input nice-select-wraper">
              <select style={{ backgroundColor: '#eaf2fc' }}
                onChange={(e) => handleFormInput({ country: { body: e.target.value, error: null } })}>
                {AllCountries.map((country, i) => (
                  <option key={i}>{country.name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label htmlFor="">Marial status*</label>
          <p style={{ fontSize: '12px', color: 'red', textAlign: 'center', marginBottom: 0 }}>{form.maritalStatus.error || null}</p>
          <div style={{ borderColor: form.maritalStatus.error && 'red' }} className="option">
            <div className="s-input nice-select-wraper">
              <select style={{ backgroundColor: '#eaf2fc' }}
                onChange={(e) => handleFormInput({ maritalStatus: { body: e.target.value, error: null } })}>
                <option>single</option>
                <option>married</option>
                <option>divorced</option>
                {/* <option>Seperated</option> */}
              </select>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="">Description*</label>
          <p style={{ fontSize: '12px', color: 'red', textAlign: 'center', marginBottom: 0 }}>{form.bio.error || null}</p>
          <textarea style={{ borderColor: form.bio.error && 'red', minHeight: 150 }} className="my-form-control" value={form.bio.body}
            onChange={(e) => handleFormInput({ bio: { body: e.target.value, error: null } })}
            placeholder="Write a little description about you..." />
        </div>
        <p style={{ textAlign: 'center', color: 'red' }}>{error || authState.networkError || null}</p>
        <button className="custom-button" onClick={handleSubmit} >
          {authState.networkLoading ? 'Loading...' : 'Register'}
        </button>
        <p style={{ marginTop: '30px' }} className="f-pass">
          Already have an account? <Link to="/login">Login now</Link>
        </p>
      </form >
    </div >
  )
}

export default RegForm