import React, { useContext, useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import BreadcrumbHeader from '../../components/BreadcrumbHeader'
import PageWrapper from '../../hoc/PageWrapper'
import axiosInstance from '../../utils/axiosInstance'
import { GlobalContext } from '../../contexts/Provider'
import TopBar from './sections/TopBar'
import WritePost from '../../components/WritePost'
import LeftProfile from './sections/LeftProfile'
import Topic from '../../components/Topic'
import Preloader from '../../components/Preloader'
import RightProfile from './sections/RightProfile'
import ToastComponent from '../../components/ToastComponent'
import toastInstance from '../../utils/toast'

const Community = () => {
  const { authState: { user } } = useContext(GlobalContext)

  const [posts, setPosts] = useState([])
  const [text, setText] = useState('')
  const [images, setImages] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [postLoading, setPostLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [limit, setLimit] = useState(5)
  const [query, setQuery] = useState('')
  const [searchParam] = useSearchParams()
  const newQuery = searchParam.get('tab')

  useEffect(() => {
    setQuery(newQuery)
  }, [newQuery])


  let searchCrit = '-createdAt'
  if (query === 'popular') { searchCrit = 'content' }
  else if (query === 'recent') { searchCrit = '-updatedAt' }
  else if (query === 'media') { searchCrit = 'media' }
  else { searchCrit = '-createdAt' }
  useEffect(() => {
    setLoading(true)
    axiosInstance.get(`/post?sort=${searchCrit}&limit=${limit}`)
      .then(res => {
        setLoading(false)
        if (res.data.data) setPosts(res.data.data)
        if (res.data.count) setCount(res.data.count)
      }).catch(err => {
        setLoading(false)
        toastInstance('An error occurred while getting topics', true)
      })
  }, [searchCrit])

  const getMorePost = (newLimit) => {
    console.log('limit', newLimit)
    setLoadingMore(true)
    axiosInstance.get(`/post?sort=-createdAt&limit=${newLimit}`)
      .then(res => {
        setLoadingMore(false)
        console.log('res.data', res.data)
        if (res.data.data) setPosts(res.data.data)
        if (res.data.count) setCount(res.data.count)
      }).catch(err => {
        setLoadingMore(false)
        toastInstance('An error occurred while getting more topics', true)
      })
  }

  const loadMore = () => {
    setLimit(limit + 5)
    getMorePost(limit + 5)
  }

  const filterData = (id) => {
    const newPosts = posts.filter(post => post._id !== id)
    setPosts(newPosts)
  }

  const handleDeletePost = (postId) => {
    axiosInstance.delete(`/post/${postId}`)
      .then(res => {
        if (res.data.data) filterData(res.data.data._id)
        toastInstance('Post deleted')
        console.log(res.data)
      }).catch(err => {
        toastInstance('Post not deleted', true)
      })
  }

  const handleLike = (id) => {
    axiosInstance.patch(`/post/like/${id}`)
      .then(res => {
        const copyPosts = [...posts]
        const createdPost = copyPosts.find(post => post._id === res.data.data._id)
        createdPost.likes = res.data.data.likes
        setPosts(copyPosts)
        console.log('res.data', res.data)
      }).catch(err => {
        toastInstance('An error occurred while liking post', true)
      })
  }

  const handleSubmit = () => {
    const formData = new FormData()
    if (text) formData.append('content', text)
    else return setError('Enter a text')
    console.log('images', images)
    if (images) formData.append('image', images)
    else return setError('Choose image')
    for (let [key, value] of formData) {
      console.log(`${key}: ${value}`)
    }
    setPostLoading(true)
    axiosInstance.post('/post', formData)
      .then(res => {
        setText('')
        setImages(null)
        setPostLoading(false)
        addPost(res.data.data)
        console.log('res.data', res.data)
        toastInstance('Your post has been created')
      }).catch(err => {
        setPostLoading(false)
        console.log('err', err.response)
        toastInstance('An error occurred while creating your post', true)
      })
  }

  const addPost = (newPost) => {
    newPost.author = user
    const newPosts = [newPost, ...posts]
    setPosts(newPosts)
  }

  const handleFileChange = (files) => {
    setImages(files[0])
  }

  const handleTextChange = e => {
    setText(e.target.value)
    setError(null)
  }

  return (
    <PageWrapper>
      <BreadcrumbHeader header={'Community'} links={[<Link to='/'>Home</Link>, 'Community']} />
      <div className="single-community-menu">
        <div className="container">
          <div className="row">
            <TopBar />
          </div>
        </div>
      </div>
      <section className="profile-section single-community">
        <div className="container">
          <div className="row justify-content-center">
            <LeftProfile />
            <div className="col-lg-6">
              <div className="profile-main-content">
                {!query &&
                  <WritePost
                    handleTextChange={handleTextChange} text={text} image={images}
                    user={user} handleSubmit={handleSubmit} error={error}
                    postLoading={postLoading} handleFileChange={handleFileChange} />
                }
                {!loading ?
                  <>
                    {posts.map((post, i) => (
                      <Topic key={i} openComment={false} handleLike={handleLike} post={post} handleDelete={handleDeletePost} />
                    ))}
                    {count > posts.length &&
                      <a onClick={loadMore} className="load-more">
                        {loadingMore ? 'Loading...' : 'Load More'}
                      </a>}
                  </>
                  : <Preloader />}
              </div>
            </div>
            <RightProfile />
          </div>
        </div>
      </section>
      <ToastComponent />
    </PageWrapper>
  )
}

export default Community

