import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { GlobalContext } from '../../../contexts/Provider'
import localImage from '../../../assets/avatar.jpeg'

const LeftSection = () => {
  const { authState: { user } } = useContext(GlobalContext)
  const date = new Date().getFullYear()

  return (
    <div className="col-xl-4 col-lg-5 col-md-7">
      <div className="left-profile-area">
        <div className="profile-about-box">
          <div className="top-bg"></div>
          <div className="p-inner-content">
            <div className="profile-img">
              <Link to={`/user-profile/${user._id}`}>
                <img style={{ width: 120, height: 120 }} src={user.profileImage || localImage} alt="" />
                <div className="active-online"></div>
              </Link>
            </div>
            <h5 className="name">
              {`${user.firstname} ${user.lastname}`}
            </h5>
            <ul className="p-b-meta-one">
              <li>
                <span>{(date - Number(user.dob?.split('-')[0]))} Years Old</span>
              </li>
              <li>
                <span> <i className="fas fa-map-marker-alt"></i>{user.city}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeftSection