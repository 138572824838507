import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const TopBar = () => {
  const { search } = useLocation()
  return (
    <div className="col-lg-12">
      <ul className="top-menu">
        <li><Link to="/community" className={search === '' ? 'active' : null}>Activity</Link></li>
        <li><Link to="/community?tab=popular" className={search === '?tab=popular' ? 'active' : null}>Popular</Link></li>
        <li><Link to="/community?tab=recent" className={search === '?tab=recent' ? 'active' : null}>Recent</Link></li>
        <li><Link to="/community?tab=media" className={search === '?tab=media' ? 'active' : null}>Media</Link></li>
      </ul>
    </div>
  )
}

export default TopBar