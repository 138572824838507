import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PageWrapper from '../../hoc/PageWrapper'
import verifyImg from "../../assets/images/c-image.png"
import { GlobalContext } from '../../contexts/Provider'
import { verifyUser } from '../../contexts/actions/auth'
import axiosInstance from '../../utils/axiosInstance'
import toastInstance from '../../utils/toast'
import ToastComponent from '../../components/ToastComponent'

const VerifyUser = () => {
  const { authState, authDispatch } = useContext(GlobalContext)
  const [token, setToken] = useState({ body: '', error: null })
  const navigate = useNavigate()

  useEffect(() => {
    if (authState?.user?.verified) {
      toastInstance('Token verified', true)
      navigate('/community')
    }
  }, [authState.user])


  const handleSubmit = () => {
    if (!token.body) setToken({ body: '', error: 'Enter Token' })
    else verifyUser(token.body)(authDispatch)
  }

  const refreshToken = () => {
    const refEmail = authState?.user?.email
    if (refEmail) {
      axiosInstance.post('/auth/verify/refresh', { email: refEmail })
        .then(res => {
          console.log('res.data', res.data)
          toastInstance('Token sent')
        }).catch(err => {
          console.log('err.response', err.response)
        })
    } else {
      toastInstance('No email, go and login', true)
    }
  }

  return (
    <PageWrapper>
      <section className="profile-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xs-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">
              <div className="container" >
                <div className="top-img">
                  <img style={{ width: '100%', height: 'auto' }} src={verifyImg} alt="" />
                </div>
                <div className="main-content">
                  <h4 className="title">
                    Check Your Inbox, Please!
                  </h4>
                  <p>
                    A token to verify your account has been sent to the email supplied.
                    Please copy the token and paste it in the input field below to verify email.
                    Check all your folders including the spam folder.
                  </p>
                  <div className="form-group">
                    <label htmlFor="">Token*</label>
                    <p style={{ fontSize: '12px', color: 'red', textAlign: 'center', marginBottom: 0 }}>{token.error || null}</p>
                    <input style={{ borderColor: token.error && 'red' }} type="text" className="my-form-control"
                      onChange={(e) => setToken({ body: e.target.value, error: null })}
                      placeholder="Enter Token" value={token.body} />
                  </div>
                  <p style={{ textAlign: 'center', color: 'red' }}>{authState.networkError || null}</p>
                  <button className="custom-button" onClick={handleSubmit} >
                    {authState.networkLoading ? 'Verifying...' : 'Verify'}
                  </button>
                  <p style={{ marginTop: '30px' }} className="f-pass">
                    Didn't get e-mail or token expired?
                    <span style={{ color: 'blue', fontWeight: 'bold' }} onClick={refreshToken} > Send again</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastComponent />
    </PageWrapper>
  )
}

export default VerifyUser