import React from 'react'
import { Link } from 'react-router-dom'

const Product = ({ id, img, reviewsNo, price, name }) => {
  return (
    <div className="col-lg-4 col-md-6">
      <div className="single-product">
        <div className="img">
          <img src={img} alt="" />
          <span className="sale">Sale</span>
          <ul className="more-links">
            <li>
              <Link to={`/shop/${id}`}>
                <i className="fas fa-sliders-h"></i>
              </Link>
            </li>
            <li>
              <Link to={`/shop/${id}`}>
                <i className="fas fa-heart"></i>
              </Link>
            </li>
            <li>
              <Link to={`/shop/${id}`}>
                <i className="far fa-eye"></i>
              </Link>
            </li>
          </ul>
        </div>
        <div className="content">
          <span className="price">
            {price}
          </span>
          <h4 className="title">
            {name}
          </h4>
          <div className="review-area">
            <div className="stars">
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
              <i className="fas fa-star"></i>
            </div>
            <span className="separator">
              |
            </span>
            <span className="review-content">
              {reviewsNo} review
            </span>
          </div>
          <a className="hover-effect" to="single-shope"><span
            className="font-16-semibold">Buy Now</span></a>
        </div>
      </div>
    </div>
  )
}

export default Product