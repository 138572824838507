import React from 'react'
import AuthTopMenuArea from '../../components/Auth/AuthTopMenuArea'
import RegForm from './sections/RegForm'
// import RegForm from1 './sections/RegForm'1
// import RegForm2 from './sections/RegForm2'
// import RegForm3 from './sections/RegForm3'

const Register = () => {
  return (
    <div>
      <section className="log-reg">
        <AuthTopMenuArea />
        <div className="container">
          <div className="row justify-content-end">
            <div className="image">
            </div>
            <div className="col-lg-7">
              <div className="log-reg-inner">
                <div className="section-header">
                  <h2 className="title">
                    Welcome to 2Discreet
                  </h2>
                  {/* <p>Let's create your profile! Just fill in the fields below, and we’ll get you a new account.</p> */}
                  <p>You are almost there, input your account details to help you Sign In and recover your account when needed .</p>
                  {/* <p>Let's get to know more about you and your fantacies, so we will know how to serve you better.</p> */}
                </div>
                <RegForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  )
}

export default Register