export default {
  networkLoading: false,
  networkError: null,
  user: null,
  isAuthenticated: false,
  productData: [
    {
      name: 'Butterfly Rings',
      reviewsNo: '229',
      id: '1',
      img: 'assets/images/shoping/ShopPage_01/01.jpg',
      price: '$25'
    }, {
      name: 'Butterfly Rings',
      reviewsNo: '229',
      id: '2',
      img: 'assets/images/shoping/ShopPage_01/02.jpg',
      price: '$25'
    }, {
      name: 'Butterfly Rings',
      reviewsNo: '229',
      id: '3',
      img: 'assets/images/shoping/ShopPage_01/03.jpg',
      price: '$25'
    }, {
      name: 'Butterfly Rings',
      reviewsNo: '229',
      id: '4',
      img: 'assets/images/shoping/ShopPage_01/04.jpg',
      price: '$25'
    }, {
      name: 'Butterfly Rings',
      reviewsNo: '229',
      id: '5',
      img: 'assets/images/shoping/ShopPage_01/05.jpg',
      price: '$25'
    }, {
      name: 'Butterfly Rings',
      reviewsNo: '229',
      id: '6',
      img: 'assets/images/shoping/ShopPage_01/06.jpg',
      price: '$25'
    }, {
      name: 'Butterfly Rings',
      reviewsNo: '229',
      id: '7',
      img: 'assets/images/shoping/ShopPage_01/07.jpg',
      price: '$25'
    }, {
      name: 'Butterfly Rings',
      reviewsNo: '229',
      id: '8',
      img: 'assets/images/shoping/ShopPage_01/08.jpg',
      price: '$25'
    }, {
      name: 'Butterfly Rings',
      reviewsNo: '229',
      id: '9',
      img: 'assets/images/shoping/ShopPage_01/09.jpg',
      price: '$25'
    }, {
      name: 'Butterfly Rings',
      reviewsNo: '229',
      id: '10',
      img: 'assets/images/shoping/ShopPage_01/10.jpg',
      price: '$25'
    }, {
      name: 'Butterfly Rings',
      reviewsNo: '229',
      id: '11',
      img: 'assets/images/shoping/ShopPage_01/11.jpg',
      price: '$25'
    }, {
      name: 'Butterfly Rings',
      reviewsNo: '229',
      id: '12',
      img: 'assets/images/shoping/ShopPage_01/12.jpg',
      price: '$25'
    },
  ]
};