import React from 'react'
import localImage from '../assets/avatar.jpeg'

const WritePost = ({ handleTextChange, image, handleFileChange, postLoading, handleSubmit, error, user, text }) => {
  return (
    <div className="write-post-area">
      <div className="write-area">
        <img src={user.profileImage || localImage} alt="" />
        <textarea
          onChange={handleTextChange} value={text}
          placeholder={`Whats on your mind, ${user.firstname}`} />
        <div style={{ textAlign: 'center', color: 'red', fontSize: 12, margin: '0 auto' }}>{error || null}</div>
      </div>
      <div>
        {/* {images.map((image, i) => ( */}
        {/* {image && <img style={{ width: 50, height: 40, borderRadius: 7 }} src={image.name} alt='' />} */}
        {/* ))} */}
      </div>
      <div className="submit-area">
        <div className="left">
          <a className="upload-btn">
            <label>
              <input accept='image/*' style={{ display: 'none' }}
                type="file" onChange={(e) => handleFileChange(e.target.files)} />
              <i className="fas fa-paperclip" />
            </label>
          </a>
          <div className="select-area">
            <select className="nice-select select-bar">
              <option value="">Public</option>
              <option value="">Friends</option>
              <option value="">Only me</option>
            </select>
          </div>
        </div>
        <div className="right">
          <a onClick={handleSubmit} className="custom-button">{postLoading ? 'Posting...' : 'Post'}</a>
        </div>
      </div>
    </div>
  )
}

export default WritePost