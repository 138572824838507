import React from 'react'
import { Link } from 'react-router-dom'

const ScrollToTop = () => {
  return (
    <>
      <div className="overlay"></div>
      <Link to="/" className="scrollToTop">
        <i className="fas fa-angle-up"></i>
      </Link>
    </>
  )
}

export default ScrollToTop