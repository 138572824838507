import React from 'react'
import { Link } from 'react-router-dom'

const ResetPassword = () => {
  return (
    <div className="modal fade" id="email-confirm" tabIndex="-1" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="top-img">
              <img src="../../../assets/images/c-image.png" alt="" />
            </div>
            <div className="main-content">
              <h4 className="title">
                Check Your Inbox, Please!
              </h4>
              <p>
                A link to verify account has been sent to the email supplied. Please open the link and follow the prompts
                to verify
                email.
              </p>
              <p className="send-again">
                Didn't get e-mail? <Link to="/">
                  Send it again
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword