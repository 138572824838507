import {
  LOGIN_USER,
  REGISTER_USER,
  NETWORK_LOADING,
  NETWORK_ERROR,
  LOGOUT_USER,
  SET_USER,
  VERIFY_USER
} from '../actions/types';

const auth = (state, action) => {
  switch (action.type) {
    case REGISTER_USER:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        networkLoading: false,
        networkError: null
      }
    case SET_USER:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      }
    case LOGIN_USER:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        networkLoading: false,
        networkError: null
      }
    case VERIFY_USER:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        networkLoading: false,
        networkError: null
      }
    case NETWORK_LOADING:
      return {
        ...state,
        networkLoading: true,
        networkError: null,
      }
    case NETWORK_ERROR:
      return {
        ...state,
        networkLoading: false,
        networkError: action.payload
      }
    case LOGOUT_USER:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      }
    default:
      return state
  }
}

export default auth;