import React from 'react'
import image1 from "../../../assets/images/cummunity/s-m-profile.png"

const LeftProfile = () => {
  return (
    <div className="col-lg-3 hide_small">
      <div className="left-profile-area">
        <div className="c-group-info">
          <div className="profile-image">
            <img src={image1} alt="" />
          </div>
          <div className="group-content">
            <p className="time">
              Active
            </p>
            <h4 className="title">
              2Discreet Community
            </h4>
            <p className="text">
              Find the love of your life here
            </p>
            <p className="security">
              <i className="fas fa-globe-americas"></i>Public Community
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LeftProfile