import axios from 'axios';
import { BACKEND_URL } from './constants'

const accessToken = localStorage.getItem('accessToken');

const axiosInstance = axios.create({
  baseURL: BACKEND_URL,
  headers: {
    accept: '*/*',
    'Content-Type': 'application/json',
    authorization: `Bearer ${accessToken}`
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    console.log('config', config)
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default axiosInstance;