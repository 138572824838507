import React from 'react'
import { Link } from 'react-router-dom'
import BreadcrumbHeader from '../../../components/BreadcrumbHeader'
import PageWrapper from '../../../hoc/PageWrapper'

const UserSettingCon = ({ children }) => {

  return (
    <PageWrapper>
      <BreadcrumbHeader header={'Settings'} links={[<Link to='/'>Home</Link>, 'Settings']} />
      <section className="user-setting-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-md-5">
              <div className="accordion" id="accordionExample">
                <div className="card">
                  <div className="card-header" id="headingOne">
                    <button className="" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                      aria-controls="collapseOne">
                      <div className="icon">
                        <i className="fas fa-user"></i>
                      </div>
                      <span>My Profile</span>
                      <div className="t-icon">
                        <i className="fas fa-plus"></i>
                        <i className="fas fa-minus"></i>
                      </div>
                    </button>
                  </div>

                  <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                    data-parent="#accordionExample">
                    <div className="card-body">
                      <ul className="links">
                        <li>
                          <Link to='/user-setting'>Profile Info</Link>
                        </li>
                        {/* <li>
                          <Link to="/user-notification">Notifications</Link>
                        </li>
                        <li>
                          <Link to="/user-friend-request">Friend Requests</Link>
                        </li>
                        <li>
                          <Link to="/user-badges">Badges</Link>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <button className="collapsed" type="button" data-toggle="collapse"
                      data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <div className="icon">
                        <i className="fas fa-cogs"></i>
                      </div>
                      <span>
                        Account
                      </span>
                      <div className="t-icon">
                        <i className="fas fa-plus"></i>
                        <i className="fas fa-minus"></i>
                      </div>
                    </button>
                  </div>
                  <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo"
                    data-parent="#accordionExample">
                    <div className="card-body">
                      <ul className="links">
                        <li>
                          <Link className="active" to="/user-account-info">Account Info</Link>
                        </li>
                        <li>
                          <Link to="/user-change-pass">Change Password</Link>
                        </li>
                        {/* <li>
                          <Link to="/user-privicy-setting">Privacy Settings</Link>
                        </li> */}
                        {/* <li>
                          <Link to="/user-verify-account">Verified account</Link>
                        </li>
                        <li>
                          <Link to="/user-close-account">Close Account</Link>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="card">
                  <div className="card-header" id="headingThree">
                    <button className="collapsed" type="button" data-toggle="collapse"
                      data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <div className="icon">
                        <i className="far fa-credit-card"></i>
                      </div>
                      <span>
                        Subscriptions & Payments
                      </span>
                      <div className="t-icon">
                        <i className="fas fa-plus"></i>
                        <i className="fas fa-minus"></i>
                      </div>
                    </button>
                  </div>
                  <div id="collapseThree" className="collapse" aria-labelledby="headingThree"
                    data-parent="#accordionExample">
                    <div className="card-body">
                      <ul className="links">
                        <li>
                          <Link to="/user-billing">Billing & Payout</Link>
                        </li>
                        <li>
                          <Link to="/user-plan">Upgrade Membership Plan</Link>
                        </li>
                        <li>
                          <Link to="/user-purchase">View Purchase History</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            {children}
          </div>
        </div>
      </section>
    </PageWrapper>
  )
}

export default UserSettingCon