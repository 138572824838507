import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { loginUser } from '../../../contexts/actions/auth'
import { GlobalContext } from '../../../contexts/Provider'
import ResetPassword from './ResetPassword'

const LoginForm = () => {
  const { authDispatch, authState } = useContext(GlobalContext)
  const [form, setForm] = useState({
    username: { body: '', error: null },
    password: { body: '', error: null }
  })
  const navigate = useNavigate()

  useEffect(() => {
    localStorage.removeItem('accessToken')
  }, [])

  useEffect(() => {
    if (authState.isAuthenticated) navigate('/community')
    // return () => setForm({
    //   username: { body: '', error: null },
    //   password: { body: '', error: null }
    // })
  }, [authState.isAuthenticated])

  const handleFormInput = (value) => {
    setForm({ ...form, ...value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!form.password.body || !form.username.body) {
      if (!form.username.body) setForm(form => ({ ...form, username: { body: '', error: 'username is required' } }))
      if (!form.password.body) setForm(form => ({ ...form, password: { body: '', error: 'password is required' } }))
    }
    else {
      const data = {
        username: form.username.body,
        password: form.password.body,
      }
      loginUser(data)(authDispatch)
    }
  }

  return (
    <>
      <div className="main-content inloginp">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="">Your Username</label>
            <p style={{ fontSize: '12px', color: 'red', textAlign: 'center', marginBottom: 0 }}>{form.username.error || null}</p>
            <input onChange={(e) => handleFormInput({ username: { body: e.target.value, error: null } })}
              type="text" name='username' value={form.username.body}
              style={{ borderColor: form.username.error && 'red' }}
              className="my-form-control" placeholder="Enter Your Username" />
          </div>
          <div className="form-group">
            <label htmlFor="">Password</label>
            <p style={{ fontSize: '12px', color: 'red', textAlign: 'center', marginBottom: 0 }}>{form.password.error || null}</p>
            <input type="password" className="my-form-control" style={{ borderColor: form.password.error && 'red' }}
              onChange={(e) => handleFormInput({ password: { body: e.target.value, error: null } })}
              placeholder="Enter Your Password" value={form.password.body} />
          </div>
          <p className="f-pass">
            Forgot your password? <Link to="/">Recover password</Link>
          </p>
          <p style={{ textAlign: 'center', color: 'red' }}>{authState.networkError || null}</p>
          <div className="button-wrapper">
            <button onClick={handleSubmit} type="submit" style={{ marginTop: 0, marginBottom: 10 }} className="custom-button">
              {authState.networkLoading ? 'Loading...' : 'Sign In'}
            </button>
          </div>
          <p className="f-pass">
            Don't have an account? <Link to="/register">Register now</Link>
          </p>
          {/* <div className="or">
          <p>OR</p>
        </div>
        <div className="or-content">
          <p>Sign in with your email</p>
          <Link to="/" className="or-btn"><img src="../../../assets/images/google.png" alt="" /> Sign Up with Google</Link>
          <p className="or-signup">
            Don't have an account? <Link to="/register">
              Sign up here
            </Link>
          </p>
        </div> */}
        </form>
      </div>
      <ResetPassword />
    </>
  )
}

export default LoginForm