import React, { useContext, useState } from 'react'
import TimeAgo from "javascript-time-ago"
import en from "javascript-time-ago/locale/en"
import { Link } from 'react-router-dom'
import { GlobalContext } from '../contexts/Provider'
import localImage from '../assets/avatar.jpeg'

const Topic = ({ post, handleDelete, handleLike, openComment, handleComment, handleDeleteComment }) => {
  TimeAgo.addLocale(en)
  const timeAgo = new TimeAgo("en-US")
  const [content, setContent] = useState('')
  const { authState: { user } } = useContext(GlobalContext)

  const deleteComment = (commentId) => {
    if (window.confirm('Are you sure you want to delete this comment')) {
      handleDeleteComment(commentId)
    }
  }

  const deletePost = (postId) => {
    if (window.confirm('Are you sure you want to delete this comment')) {
      handleDelete(postId)
    }
  }

  return (
    <div className="profile-single-post">
      <div className="p-s-p-header-area">
        <div className="img">
          <img style={{ borderRadius: 50, width: 40, height: 40 }} src={post.author?.profileImage || localImage} alt="" />
          <div className="active-online"></div>
        </div>
        <Link to={`/user-profile/${post.author?._id}?tab=activity`}>
          <h6 className="name">{`${post.author?.firstname} ${post.author?.lastname}`}</h6>
        </Link>
        <span className="is-varify"><i className="fas fa-check"></i></span>
        <span className="usewrname">{`@${post.author?.username}`}</span>
        <span className="post-time">{timeAgo.format(new Date(post.createdAt).getTime(), 'twitter-now')}</span>
      </div>
      <Link style={{ color: '#333' }} to={{ pathname: `/topic/${post._id}`, state: post }}>
        <div className="p-s-p-content">
          <p>{post.content}</p>
          {post.images.map((image, i) => (
            <div key={i} className="img">
              <img src={image.url} alt="" />
              <div className="active-online" />
            </div>
          ))}
        </div>
      </Link>
      <div className="p-s-p-content-footer">
        <div className="left">
          <Link style={{ color: '#333' }} className="link" to={`/topic/${post._id}`}>{post.comments.length} <i className="far fa-comment" /></Link>
          <a style={{ cursor: 'pointer' }} onClick={() => handleLike(post._id)} className="link">
            {post.likes.length} <i className={post.likes.includes(user._id) ? "fas fa-star" : "far fa-star"} />
          </a>
        </div>
        {post.author?._id === user._id &&
          <div className="right" >
            <a className="link" onClick={() => deletePost(post._id)}><i className="far fa-trash-alt"></i></a>
          </div>}
      </div>
      {openComment &&
        <>
          <div style={{ width: '100%', marginTop: 20 }}>
            {post.comments.map((comment, i) => (
              <div>
                <h3 style={{ marginTop: 5, paddingTop: 3, paddingBottom: 7, borderTop: '1px solid rgba(0, 0, 0, 0.1)' }}>
                  {post.comments.length} Replies</h3>
                <div className="p-s-p-header-area">
                  <div className="img">
                    <img style={{ borderRadius: 40, width: 30, height: 30 }} src={comment.author?.profileImage || localImage} alt="" />
                    <div className="active-online"></div>
                  </div>
                  <Link to={`/user-profile/${comment.author?._id}?tab=activity`}>
                    <h6 className="name">{`${comment.author?.firstname} ${comment.author?.lastname}`}</h6>
                  </Link>
                  <span className="is-varify"><i className="fas fa-check"></i></span>
                  <span className="comment-time">{timeAgo.format(new Date(comment.createdAt).getTime(), 'twitter-now')}</span>
                </div>
                <Link style={{ color: '#333' }} to={`/topic/${comment._id}`}>
                  <div className="p-s-p-content">
                    <p>{comment.content}</p>
                  </div>
                </Link>
                {(comment.author?._id === user._id || post.author?._id === user._id) &&
                  <div>
                    <a className="link" onClick={() => deleteComment(comment._id)}><i className="far fa-trash-alt"></i></a>
                  </div>}
              </div>
            ))}
          </div>
          <div className="p-s-p-content-footer">
            <div className='left'>
              <input
                onChange={e => setContent(e.target.value)}
                placeholder='Comment...' value={content} className="comment"
                onKeyPress={e => e.key == 'Enter' && setContent(e.target.value)} />
            </div>
            <div className='right'>
              <a className='link'
                onClick={() => {
                  handleComment({ postId: post._id, content })
                  setContent('')
                }}
              >
                <i className="fab fa-telegram-plane" />
              </a>
            </div>
          </div>
        </>}
    </div>
  )
}

export default Topic