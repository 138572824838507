import axios from '../../utils/axiosInstance';
import {
  LOGIN_USER,
  REGISTER_USER,
  NETWORK_LOADING,
  NETWORK_ERROR,
  LOGOUT_USER,
  SET_USER,
  VERIFY_USER
} from './types';

export const registerUser = (userData) => (dispatch) => {
  dispatch({ type: NETWORK_LOADING })
  axios.post('/auth/register', userData)
    .then(res => {
      localStorage.setItem('accessToken', res.data.accessToken)
      console.log('res.data', res.data)
      return dispatch({
        type: REGISTER_USER,
        payload: res.data.user
      })
    })
    .catch(err => {
      dispatch({
        type: NETWORK_ERROR,
        payload: err.response?.data.message || 'Something went wrong, try again'
      })
    })
}

export const setUser = (data) => (dispatch) => {
  return dispatch({
    type: SET_USER,
    payload: data
  })
}

export const loginUser = (userData) => (dispatch) => {
  dispatch({ type: NETWORK_LOADING })
  localStorage.removeItem('accessToken')
  axios.post('/auth/login', userData)
    .then(res => {
      localStorage.setItem('accessToken', res.data.accessToken)
      console.log('res.data', res.data)
      return dispatch({
        type: LOGIN_USER,
        payload: res.data.user,
      })
    })
    .catch(err => {
      console.log('err.response', err.response)
      dispatch({
        type: NETWORK_ERROR,
        payload: err?.response?.data?.message || 'Something went wrong, try again'
      })
    })
}

export const verifyUser = (token) => (dispatch) => {
  dispatch({ type: NETWORK_LOADING })
  axios.get(`/auth/verify/${token}`)
    .then(res => {
      console.log('res.data', res.data)
      localStorage.setItem('accessToken', res.data.data.accessToken)
      return dispatch({
        type: VERIFY_USER,
        payload: res.data?.data?.user
      })
    })
    .catch(err => {
      console.log('err.response', err.response)
      dispatch({
        type: NETWORK_ERROR,
        payload: err?.response?.data?.message || 'Something went wrong, try again'
      })
    })
}

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('accessToken')
  return dispatch({
    type: LOGOUT_USER,
  })
}
