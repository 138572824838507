import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import BreadcrumbHeader from '../../../../components/BreadcrumbHeader'
import { GlobalContext } from '../../../../contexts/Provider'
import PageWrapper from '../../../../hoc/PageWrapper'
import Photos from './Photos'
import ProfileSection from '../sections/ProfileSection'
import Topbar from '../sections/Topbar'

const MainProfileCon = ({ children }) => {
  const { authState: { user } } = useContext(GlobalContext)

  return (
    <PageWrapper>
      <BreadcrumbHeader header='My Profile' links={[<Link to='/'>Home</Link>, 'Profile']} />
      <section className="profile-section">
        <div className="container">
          <div className="row justify-content-center">
            <ProfileSection user={user} />
            <div className="col-xl-5 col-lg-6">
              <div className="profile-main-content">
                <Topbar />
                {children}
              </div>
            </div>
            {/* <Photos /> */}
          </div>
        </div>
      </section>
    </PageWrapper>
  )
}

export default MainProfileCon