import React, { useContext } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { GlobalContext } from '../contexts/Provider'
import { logoutUser } from '../contexts/actions/auth'
import userImg from '../assets/avatar.jpeg'
import logo from "../assets/images/logo/logo.png"

const Navbar = () => {
  const { authState, authDispatch } = useContext(GlobalContext)
  const navigate = useNavigate()

  const handleLogout = () => {
    logoutUser()(authDispatch)
    navigate('/')
  }

  return (
    <header className="header-section">
      <div className="container">
        <div className="header-wrapper">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <ul className="menu">
            {!authState.isAuthenticated ?
              <>
                <li>
                  <NavLink className={(navDate) => navDate.isActive ? 'active' : null} to='/'>Home</NavLink>
                </li>
                <li>
                  <NavLink className={(navDate) => navDate.isActive ? 'active' : null} to="/shop">Shop</NavLink>
                </li>
                <li>
                  <NavLink className={(navDate) => navDate.isActive ? 'active' : null} to="/blog">Blog</NavLink>
                </li>
                <li>
                  <NavLink className={(navDate) => navDate.isActive ? 'active' : null} to="/login">Login</NavLink>
                </li>
                <li>
                  <NavLink className={(navDate) => navDate.isActive ? 'active' : null} to="/register">Register</NavLink>
                </li>
              </> :
              <>
                <li>
                  <NavLink className={(navDate) => navDate.isActive ? 'active' : null} to='/community'>Community</NavLink>
                </li>
                <li>
                  <NavLink className={(navDate) => navDate.isActive ? 'active' : null} to="/messanger">Messanger</NavLink>
                </li>
                <li>
                  <NavLink className={(navDate) => navDate.isActive ? 'active' : null} to="/members">Members</NavLink>
                </li>
                <li>
                  <a >More Features</a>
                  <ul className="submenu">
                    <li>
                      <Link to="/membership">Membership</Link>
                    </li>
                    <li>
                      <Link to="/game">Couples Game</Link>
                    </li>
                    <li>
                      <Link to="/members">Online Members</Link>
                    </li>
                    <li>
                      <Link to="/members">New Member</Link>
                    </li>
                    <li>
                      <Link to="/members">Featured Members</Link>
                    </li>
                    <li>
                      <Link to="/shop">Shop</Link>
                    </li>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </li>
                <li className="user-profile">
                  <a><img style={{ width: 40, height: 40, borderRadius: 50 }} src={authState.user.profileImage || userImg} alt="" /></a>
                  <ul className="submenu">
                    <li>
                      <Link to={`/user-profile/${authState.user._id}?tab=activity`}>My Profile</Link>
                    </li>
                    <li>
                      <Link to='/user-setting'>Profile settings</Link>
                    </li>
                    <li>
                      <a onClick={handleLogout}>Logout</a>
                    </li>
                  </ul>
                </li>
              </>
            }
            <li className="separator">
              <span>|</span>
            </li>
            <li>
              <div className="serch-icon">
                <i className="fas fa-search"></i>
              </div>
            </li>
            {/* <li>
              <div className="language-select">
                <select className="select-bar">
                  <option value="">EN</option>
                  <option value="">IN</option>
                  <option value="">BN</option>
                </select>
              </div>
            </li> */}
          </ul>
          <div className="header-bar d-lg-none">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Navbar