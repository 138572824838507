import React from 'react'
import { Link } from 'react-router-dom'

const Pagination = () => {
  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="pagination-area text-center">
          <Link to="/"><i className="fas fa-angle-double-left"></i><span></span></Link>
          <Link to="/">1</Link>
          <Link to="/">2</Link>
          <Link to="/" className="active">3</Link>
          <Link to="/">4</Link>
          <Link to="/">5</Link>
          <Link to="/"><i className="fas fa-angle-double-right"></i></Link>
        </div>
      </div>
    </div>
  )
}

export default Pagination