import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import localImage from '../../../assets/avatar.jpeg'
import axiosInstance from '../../../utils/axiosInstance'

const RightProfile = () => {
  const [members, setMembers] = useState([])

  useEffect(() => {
    axiosInstance.get('/user')
      .then(res => {
        setMembers(res.data.data)
      })
  }, [])

  return (
    <div className="col-lg-3 col-sm-6 hide_small">
      <div className="profile-aside-area">
        <div className="serch-area">
          <form action="#">
            <input type="text" placeholder="Search" />
            <button type="submit"><i className="fas fa-search"></i></button>
          </form>
        </div>
        <div className="recent-member">
          <h4 className="title">
            Recent Members
          </h4>
          <ul className="member-list">
            {members.map((member, i) => (
              <li key={i}><Link to={`/user-profile/${member._id}`}><img
                style={{ width: 40, height: 40, borderRadius: 50, margin: 'auto 5' }}
                src={member.profileImage || localImage} alt="" /></Link></li>
            ))}
          </ul>
        </div>
        <div className="recent-photo">
          <h4 className="title">
            Recent Photos
          </h4>
          <ul className="member-list">
            {members.map((member, i) => (
              <li key={i}><Link to={`/user-profile/${member._id}`}><img
                style={{ width: 70, height: 45, borderRadius: 5 }}
                src={member.profileImage || localImage} alt="" /></Link></li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default RightProfile