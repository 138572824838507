import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import AboutUs from './pages/AboutUs'
import Blog from './pages/Blog'
import BlogDetails from './pages/BlogDetails'
import Community from './pages/Community/Community'
import Contact from './pages/Contact'
import LandingPage from './pages/LandingPage/LandingPage'
import Login from './pages/Login/Login'
import Members from './pages/Members/Members'
import Membership from './pages/Membership'
import Messanger from './pages/Messanger/Messanger'
import Page404 from './pages/Page404'
import Register from './pages/Register/Register'
import VerifyUser from './pages/Register/VerifyUser'
import Shop from './pages/Shop/Shop'
import ShopDetails from './pages/Shop/ShopDetails'
import UserProfile from './pages/User/Profile/UserProfile'
import UserAccountInfo from './pages/User/UserSettings/UserAccountInfo'
import UserChangePass from './pages/User/UserSettings/UserChangePass'
import UserSetting from './pages/User/UserSettings/UserSetting'
import TopicDetails from './pages/Community/TopicDetails'
import Auth from './hoc/Auth';
import ProfileImageAuth from './hoc/ProfileImageAuth';
import 'react-toastify/dist/ReactToastify.css';
import './styles/css/main.css'
import './styles/css/responsive.css'
import './styles/css/all.min.css'
import "./styles/css/bootstrap.min.css"
import "./styles/css/animate.css"
import "./styles/css/flaticon.css"
import "./styles/css/magnific-popup.css"
import "./styles/css/odometer.css"
import "./styles/css/owl.carousel.min.css"
import "./styles/css/owl.theme.default.min.css"
import "./styles/css/nice-select.css"
import "./styles/css/jquery.animatedheadline.css"


function App() {
  const accessToken = localStorage.getItem('accessToken')

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={accessToken ? <Navigate to='/community' /> : <LandingPage />} />
        <Route path='/about' element={<AboutUs />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/blog-details' element={<BlogDetails />} />
        <Route path='/community' element={<Auth><Community /></Auth>} />
        <Route path='/topic/:id' element={<Auth><TopicDetails /></Auth>} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/login' element={<Login />} />
        <Route path='/members' element={<Auth><Members /></Auth>} />
        <Route path='/membership' element={<Auth><Membership /></Auth>} />
        <Route path='/messanger' element={<Auth><Messanger /></Auth>} />
        <Route path='/register' element={<Register />} />
        <Route path='/shop' element={<Shop />} />
        <Route path='/verify-user' element={<VerifyUser />} />
        <Route path='/shop/:id' element={<ShopDetails />} />
        <Route path='/user-profile/:id' element={<Auth><UserProfile /></Auth>} />
        <Route path='/user-account-info' element={<Auth><UserAccountInfo /></Auth>} />
        <Route path='/user-change-pass' element={<Auth><UserChangePass /></Auth>} />
        <Route path='/user-setting' element={<ProfileImageAuth><UserSetting /></ProfileImageAuth>} />
        <Route path='*' element={<Page404 />} />
      </Routes>
    </div>
  );
}

export default App;


