import React, { useContext } from 'react'
import { GlobalContext } from '../../../../contexts/Provider'
import Friend from './Friend'

const Friends = () => {
  const { authState: { user } } = useContext(GlobalContext)

  return (
    <div className="col-xl-5 col-lg-6">
      <div className="profile-main-content">
        <div className="profile-friends">
          {/* {user.userFriends.map((user, i) => (
              <Friend key={i} name={`${user.firstname} ${user.lastname}`} time={user.time} img={user.img} />
            ))} */}
        </div>
      </div>
    </div>
  )
}

export default Friends