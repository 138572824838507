import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import BreadcrumbHeader from '../../components/BreadcrumbHeader'
import { GlobalContext } from '../../contexts/Provider'
import PageWrapper from '../../hoc/PageWrapper'

const SingleShope = () => {
  const { id } = useParams()
  const [product, setProduct] = useState(null)
  const { authState: { productData } } = useContext(GlobalContext)

  useEffect(() => {
    const newTopic = productData.find(com => com.id === id)
    setProduct(newTopic)
  }, [id])

  return (
    <PageWrapper>
      <BreadcrumbHeader header={'Details'} links={[<Link to='/'>Home</Link>, <Link to='/shop'>Shop</Link>, 'Product details']} />
      <section className="product-details-section">
        <div className="overlay">
          <div className="container">
            <div className="row mb-90">
              <div className="col-lg-7">
                <div className="xzoom-container">
                  <img className="xzoom5" id="xzoom-magnific" src="../../assets/images/shoping/SingleShop/01.jpg/"
                    xoriginal="../../assets/images/shoping/SingleShop/01.jpg" />
                  <div className="xzoom-thumbs">
                    <div className="all-slider owl-carousel">
                      <Link to="../../assets/images/shoping/SingleShop/01.jpg">
                        <img className="xzoom-gallery5" width="80"
                          src="../../assets/images/shoping/SingleShop/01.jpg"
                          title="The description goes here" />
                      </Link>
                      <Link to="../../assets/images/shoping/SingleShop/02.jpg">
                        <img className="xzoom-gallery5" width="80"
                          src="../../assets/images/shoping/SingleShop/02.jpg"
                          title="The description goes here" />
                      </Link>
                      <Link to="../../assets/images/shoping/SingleShop/03.jpg">
                        <img className="xzoom-gallery5" width="80"
                          src="../../assets/images/shoping/SingleShop/03.jpg"
                          title="The description goes here" />
                      </Link>
                      <Link to="../../assets/images/shoping/SingleShop/04.jpg">
                        <img className="xzoom-gallery5" width="80"
                          src="../../assets/images/shoping/SingleShop/04.jpg"
                          title="The description goes here" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="right-area">
                  <div className="product-info">
                    <ul>
                      <li className="product-stock">
                        <div className="stock-box">
                          <span className="value-title">Availability:</span>
                          <span className="value-content">In Stock</span>
                        </div>
                        <div className="stock-icon">
                          <Link to="/">
                            <i className="fas fa-heart"></i>
                          </Link>
                        </div>
                      </li>
                      <li className="product-title-area">
                        <h1 className="product-title">Mirrored Swim Goggle</h1>
                      </li>
                      <li>
                        <div className="review-block">
                          <p className="star-review">
                            <Link to="/" className="star">
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                            </Link>
                            <Link to="/" className="reviews"> <span>129</span> review </Link>
                          </p>
                        </div>
                      </li>
                    </ul>


                    <div className="product-price">
                      <p className="price">$<span id="sizeprice">15.00</span></p>
                    </div>

                    <ul className="offers">
                      <li>
                        <p>Order 2, get 15% off</p>
                      </li>
                      <li>
                        <p>Order 3, get 20% off</p>
                      </li>
                    </ul>

                    <div className="product-color">
                      <p className="title">Color:</p>
                      <ul className="color-list">
                        <li>
                          <span className="box">Red</span>
                        </li>
                      </ul>
                    </div>

                    <div className="item-list">
                      <ul>
                        <li><Link to="/"><img src="../../assets/images/shoping/c1.jpg" alt="image" /></Link></li>
                        <li><Link to="/"><img src="../../assets/images/shoping/c2.jpg" alt="image" /></Link></li>
                        <li><Link to="/"><img src="../../assets/images/shoping/c3.jpg" alt="image" /></Link></li>
                        <li><Link to="/"><img src="../../assets/images/shoping/c4.jpg" alt="image" /></Link></li>
                      </ul>
                    </div>

                    <div className="shiping-area">
                      <h4 className="title">Shipping:</h4>
                      <div className="content">
                        <div className="s-drop">
                          <select className="select-bar">
                            <option value="">Processing time:1-3 business days</option>
                            <option value="1">Processing time:1-3 business days</option>
                            <option value="2">Processing time:1-3 business days</option>
                          </select>
                        </div>
                        <div className="s-drop">
                          <select className="select-bar">
                            <option value="">Free shipping via Standard Shipping</option>
                            <option value="1">Free shipping via Standard Shipping</option>
                            <option value="2">Free shipping via Standard Shipping</option>
                          </select>
                        </div>
                        <div className="s-drop">
                          <select className="select-bar">
                            <option value="">Shipping time:7-35 business days</option>
                            <option value="1">Shipping time:7-35 business days</option>
                            <option value="2">Shipping time:7-35 business days</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="input-counter d-flex">
                      <div className="qtySelector text-center">
                        <i className="fas fa-minus decreaseQty"></i>
                        <input type="text" className="qtyValue" value="1" />
                        <i className="fas fa-plus increaseQty"></i>
                      </div>
                      <button className="add-cart-btn custom-button"> <i className="icofont-shopping-cart"></i>
                        Add to Cart</button>
                    </div>

                    <div className="product-single-share">
                      <label>Share link:</label>
                      <ul className="footer-brand">
                        <li><Link to="/"><i className="fab fa-facebook-f"></i></Link></li>
                        <li><Link to="/" className="active"><i className="fab fa-twitter"></i></Link></li>
                        <li><Link to="/"><i className="fab fa-pinterest"></i></Link></li>
                        <li><Link to="/"><i className="fab fa-linkedin-in"></i></Link></li>
                        <li><Link to="/"><i className="fab fa-instagram"></i></Link></li>
                      </ul>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#frist">Product Information</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#second">Product Detail</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#third">Product feature</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#fourth">Delivery & Returns</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#fifth">Reviews</a>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade" id="frist">
                    <div className="tab-content-wrapper">
                      <p className="font-18-normal mb-30">
                        The MPS XCEED Goggles have a patented curved lens for expanded field of vision
                        allows the swimmer to sight walls and the competition without altering head
                        position. Engineered with Exo-Core technology, which combines two materials for
                        maximum performance. Compressed Micro-Gasket made of ultra-soft Softeril and
                        provides a leak
                        resistant seal and a low-profile design. Semi-rigid exoskeleton maximizes the
                        structural strength and stability of the goggle. Hydrodynamic, low-profile head
                        buckle for quick adjustments. High-quality, low-profile silicone strap with
                        textured grip effect. Clear vision is assured with anti-fog treated
                        polycarbonate lenses. Goggles have
                        ergonomic, easy to replace, interchangeable nose bridge for a customizable,
                        comfortable fit. Designed and
                        manufactured in Italy and are FINA approved.
                      </p>
                      <ul className="font-18-normal">
                        <li>
                          <i className="icofont-caret-right"></i>
                          Asymmetric and anatomically designed lens
                        </li>
                        <li>
                          <i className="icofont-caret-right"></i>
                          Wider field of vision
                        </li>
                        <li>
                          <i className="icofont-caret-right"></i>
                          High performing hydrodynamic shape
                        </li>
                        <li>
                          <i className="icofont-caret-right"></i>
                          Low profile side loops and micro head buckles
                        </li>
                        <li>
                          <i className="icofont-caret-right"></i>
                          Includes 4 nosepiece options for custom fit
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="second">
                    <div className="tab-content-wrapper-second">
                      <h4 className="title mb-30">Specification:</h4>
                      <ul className="top-content mb-30">
                        <li>
                          Lens Material:
                          <span>PC</span>
                        </li>
                        <li>
                          Head Band:
                          <span>Silica gel</span>
                        </li>
                        <li>
                          Fit People:
                          <span>Adult</span>
                        </li>
                        <li>
                          Gender:
                          <span>Unisex</span>
                        </li>
                        <li>
                          Net Weight:
                          <span>145g</span>
                        </li>
                        <li>
                          Size:
                          <span>156.5x39.1cm</span>
                        </li>
                        <li>
                          Function:
                          <span>Waterproof, Anti-fog, UV-proof</span>
                        </li>
                      </ul>


                    </div>
                  </div>
                  <div className="tab-pane fade" id="third">
                    <div className="tab-content-wrapper-third">

                      <h4 className="title mb-30">FINA Approved:</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Totam ducimus cum sunt
                        quas, exercitationem et sit a quos culpa ratione, atque nobis fugiat accusamus
                        dolores perspiciatis facilis blanditiis optio suscipit.
                      </p>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Totam ducimus cum sunt
                        quas, exercitationem et sit a quos culpa ratione, atque nobis fugiat accusamus
                        dolores perspiciatis facilis blanditiis optio suscipit.Lorem ipsum dolor sit
                        amet, consectetur adipisicing elit. Totam ducimus cum sunt quas, exercitationem
                        et sit a quos culpa ratione, atque nobis fugiat accusamus dolores perspiciatis
                        facilis blanditiis optio suscipit.
                      </p>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="fourth">
                    <div className="tab-content-wrapper-fourth">

                      <h4 className="title mb-30">Delivery & Returns:</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Totam ducimus cum sunt
                        quas, exercitationem et sit a quos culpa ratione, atque nobis fugiat accusamus
                        dolores perspiciatis facilis blanditiis optio suscipit.
                      </p>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Totam ducimus cum sunt
                        quas, exercitationem et sit a quos culpa ratione, atque nobis fugiat accusamus
                        dolores perspiciatis facilis blanditiis optio suscipit.Lorem ipsum dolor sit
                        amet, consectetur adipisicing elit. Totam ducimus cum sunt quas, exercitationem
                        et sit a quos culpa ratione, atque nobis fugiat accusamus dolores perspiciatis
                        facilis blanditiis optio suscipit.
                      </p>
                    </div>
                  </div>
                  <div className="tab-pane fade show active fifth" id="fifth">
                    <div className="tab-content-wrapper-fifth">
                      <div className="top-content-fifth mb-30">
                        <h4 className="title mb-30">Reviews</h4>
                        <div className="row d-flex justify-content-around">
                          <div className="col-lg-4">
                            <div className="averages-review text-center">
                              <div className="rating"><span>5.0</span>/5</div>
                              <div className="review-block">
                                <p className="star-review">
                                  <Link to="/" className="star">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                  </Link>
                                  <Link to="/" className="reviews"> <span>129</span> review </Link>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <ul className="rating-list">
                              <li className="rating-breakdown">

                                <div className="rating-point">
                                  <span>5 Stars</span>
                                </div>

                                <div className="progress">
                                  <div className="progress-bar full" role="progressbar"></div>
                                </div>
                                <div className="review-count">
                                  <Link to="/">(129)</Link>
                                </div>

                              </li>
                            </ul>
                            <ul className="rating-list">
                              <li className="rating-breakdown">

                                <div className="rating-point">
                                  <span>4 Stars</span>
                                </div>

                                <div className="progress">
                                  <div className="progress-bar eighty" role="progressbar"></div>
                                </div>
                                <div className="review-count">
                                  <Link to="/">(12)</Link>
                                </div>

                              </li>
                            </ul>
                            <ul className="rating-list">
                              <li className="rating-breakdown">

                                <div className="rating-point">
                                  <span>3 Stars</span>
                                </div>

                                <div className="progress">
                                  <div className="progress-bar" role="progressbar"></div>
                                </div>
                                <div className="review-count">
                                  <Link to="/">(0)</Link>
                                </div>

                              </li>
                            </ul>
                            <ul className="rating-list">
                              <li className="rating-breakdown">

                                <div className="rating-point">
                                  <span>2 Stars</span>
                                </div>

                                <div className="progress">
                                  <div className="progress-bar" role="progressbar"></div>
                                </div>
                                <div className="review-count">
                                  <Link to="/">(0)</Link>
                                </div>

                              </li>
                            </ul>
                            <ul className="rating-list">
                              <li className="rating-breakdown">

                                <div className="rating-point">
                                  <span>1 Star</span>
                                </div>

                                <div className="progress">
                                  <div className="progress-bar" role="progressbar"></div>
                                </div>
                                <div className="review-count">
                                  <Link to="/">(0)</Link>
                                </div>

                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="single-comment mb-30">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="comment-single">
                              <div className="avatar">
                                <img src="../../assets/images/shoping/reviewr1.png" className="img-fluid"
                                  alt="" />
                              </div>
                              <div className="comnt-text">
                                <div className="reply-icon">
                                  <Link to="/">Reply <i className="fas fa-reply-all"></i></Link>
                                </div>
                                <div className="comnt-info">
                                  <h5>Angel Smith</h5>
                                  <div className="review-block">
                                    <p className="star-review">
                                      <Link to="/" className="star">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                      </Link>
                                    </p>
                                  </div>

                                  <p className="date-area">November 20, 2018 at 8:31 pm</p>
                                </div>
                                <p className="">Maecenas at turpis ut lacus posuere dapibus. Fusce
                                  et sollicitudin libero, id vehicula sem. Morbi pharetra nisl
                                  eget neque commodo facilisis. Nunc malesuada dolor vitae
                                  feugiat fermentum.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single-comment mb-30">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="comment-single">
                              <div className="avatar">
                                <img src="../../assets/images/shoping/reviewr2.png" className="img-fluid"
                                  alt="" />
                              </div>
                              <div className="comnt-text">
                                <div className="reply-icon">
                                  <Link to="/">Reply <i className="fas fa-reply-all"></i></Link>
                                </div>
                                <div className="comnt-info">
                                  <h5>Pablo Hicks</h5>
                                  <div className="review-block">
                                    <p className="star-review">
                                      <Link to="/" className="star">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                      </Link>
                                    </p>
                                  </div>

                                  <p className="date-area">November 20, 2018 at 8:31 pm</p>
                                </div>
                                <p className="font-16-regular">Maecenas at turpis ut lacus posuere
                                  dapibus. Fusce et sollicitudin libero, id vehicula sem.
                                  Morbi pharetra nisl eget neque commodo facilisis. Nunc
                                  malesuada dolor vitae feugiat fermentum.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="single-comment mb-30">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="comment-single">
                              <div className="avatar">
                                <img src="../../assets/images/shoping/reviewr3.png" className="img-fluid"
                                  alt="" />
                              </div>
                              <div className="comnt-text">
                                <div className="reply-icon">
                                  <Link to="/">Reply <i className="fas fa-reply-all"></i></Link>
                                </div>
                                <div className="comnt-info">
                                  <h5>Noel Webster</h5>
                                  <div className="review-block">
                                    <p className="star-review">
                                      <Link to="/" className="star">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                      </Link>
                                    </p>
                                  </div>

                                  <p className="date-area">November 20, 2018 at 8:31 pm</p>
                                </div>
                                <p className="font-16-regular">Maecenas at turpis ut lacus posuere
                                  dapibus. Fusce et sollicitudin libero, id vehicula sem.
                                  Morbi pharetra nisl eget neque commodo facilisis. Nunc
                                  malesuada dolor vitae feugiat fermentum.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="pagination-area text-center">
                            <Link to="/"><i className="fas fa-angle-double-left"></i><span></span></Link>
                            <Link to="/">1</Link>
                            <Link to="/">2</Link>
                            <Link to="/" className="active">3</Link>
                            <Link to="/">4</Link>
                            <Link to="/">5</Link>
                            <Link to="/"><i className="fas fa-angle-double-right"></i></Link>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-9">
                          <div className="comment-form">
                            <h3 className="mb-60">Add a Review</h3>

                            <div className="star-area d-flex justify-content-between">
                              <h5>Your Rating:</h5>
                              <ul className="star-list">
                                <li>
                                  <div className="review-block">
                                    <div className="star-review">
                                      <div to="/" className="star">
                                        <i className="fas fa-star"></i>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="review-block">
                                    <div className="star-review">
                                      <div className="star">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="review-block">
                                    <div className="star-review">
                                      <div to="/" className="star">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="review-block">
                                    <div className="star-review">
                                      <div to="/" className="star">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="review-block">
                                    <div className="star-review">
                                      <div to="/" className="star">
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>

                            <form action="#" method="post">
                              <div className="form-group">
                                <input type="text" className="form-control" placeholder="Name" />
                              </div>
                              <div className="form-group">
                                <input type="email" className="form-control"
                                  placeholder="Email Address *" />
                              </div>
                              <div className="form-group">
                                <input type="text" className="form-control" placeholder="Subject" />
                              </div>
                              <div className="form-group mb-30">
                                <textarea className="form-control" rows="10"
                                  placeholder="Your Message"></textarea>
                              </div>
                              <button className="custom-button" type="submit">POST COMMENT</button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="related-product-section">
              <div className="col-lg-12">
                <div className="row justify-content-center">
                  <div className="col-lg-6">
                    <div className="content">
                      <div className="section-header">
                        <h6 className="sub-title">
                          Hot Products
                        </h6>
                        <h2 className="title extra-padding">
                          Related To This Item
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="related-product-slider owl-carousel">
                      <div className="slider-item">
                        <div className="single-product">
                          <div className="img">
                            <img src="../../assets/images/shoping/ShopPage_01/01.jpg" alt="" />
                            <span className="sale">Sale</span>
                            <ul className="more-links">
                              <li>
                                <Link to="/">
                                  <i className="fas fa-sliders-h"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="/">
                                  <i className="fas fa-heart"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="/">
                                  <i className="far fa-eye"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="content">
                            <span className="price">
                              $15
                            </span>
                            <h4 className="title">
                              Butterfly Rings
                            </h4>
                            <div className="review-area">
                              <div className="stars">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                              </div>
                              <span className="separator">
                                |
                              </span>
                              <span className="review-content">
                                229 review
                              </span>
                            </div>
                            <a className="hover-effect" to="/"><span className="font-16-semibold">Buy
                              Now</span></a>
                          </div>
                        </div>
                      </div>
                      <div className="slider-item">
                        <div className="single-product">
                          <div className="img">
                            <img src="../../assets/images/shoping/ShopPage_01/02.jpg" alt="" />
                            <span className="sale">Sale</span>
                            <ul className="more-links">
                              <li>
                                <Link to="/">
                                  <i className="fas fa-sliders-h"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="/">
                                  <i className="fas fa-heart"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="/">
                                  <i className="far fa-eye"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="content">
                            <span className="price">
                              $15
                            </span>
                            <h4 className="title">
                              Butterfly Rings
                            </h4>
                            <div className="review-area">
                              <div className="stars">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                              </div>
                              <span className="separator">
                                |
                              </span>
                              <span className="review-content">
                                229 review
                              </span>
                            </div>
                            <a className="hover-effect" to="/"><span className="font-16-semibold">Buy
                              Now</span></a>
                          </div>
                        </div>
                      </div>
                      <div className="slider-item">
                        <div className="single-product">
                          <div className="img">
                            <img src="../../assets/images/shoping/ShopPage_01/03.jpg" alt="" />
                            <span className="sale">Sale</span>
                            <ul className="more-links">
                              <li>
                                <Link to="/">
                                  <i className="fas fa-sliders-h"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="/">
                                  <i className="fas fa-heart"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="/">
                                  <i className="far fa-eye"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="content">
                            <span className="price">
                              $15
                            </span>
                            <h4 className="title">
                              Butterfly Rings
                            </h4>
                            <div className="review-area">
                              <div className="stars">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                              </div>
                              <span className="separator">
                                |
                              </span>
                              <span className="review-content">
                                229 review
                              </span>
                            </div>
                            <a className="hover-effect" to="/"><span className="font-16-semibold">Buy
                              Now</span></a>
                          </div>
                        </div>
                      </div>
                      <div className="slider-item">
                        <div className="single-product">
                          <div className="img">
                            <img src="../../assets/images/shoping/ShopPage_01/04.jpg" alt="" />
                            <span className="sale">Sale</span>
                            <ul className="more-links">
                              <li>
                                <Link to="/">
                                  <i className="fas fa-sliders-h"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="/">
                                  <i className="fas fa-heart"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="/">
                                  <i className="far fa-eye"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="content">
                            <span className="price">
                              $15
                            </span>
                            <h4 className="title">
                              Butterfly Rings
                            </h4>
                            <div className="review-area">
                              <div className="stars">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                              </div>
                              <span className="separator">
                                |
                              </span>
                              <span className="review-content">
                                229 review
                              </span>
                            </div>
                            <a className="hover-effect" to="/"><span className="font-16-semibold">Buy
                              Now</span></a>
                          </div>
                        </div>
                      </div>
                      <div className="slider-item">
                        <div className="single-product">
                          <div className="img">
                            <img src="../../assets/images/shoping/ShopPage_01/05.jpg" alt="" />
                            <span className="sale">Sale</span>
                            <ul className="more-links">
                              <li>
                                <Link to="/">
                                  <i className="fas fa-sliders-h"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="/">
                                  <i className="fas fa-heart"></i>
                                </Link>
                              </li>
                              <li>
                                <Link to="/">
                                  <i className="far fa-eye"></i>
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="content">
                            <span className="price">
                              $15
                            </span>
                            <h4 className="title">
                              Butterfly Rings
                            </h4>
                            <div className="review-area">
                              <div className="stars">
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                                <i className="fas fa-star"></i>
                              </div>
                              <span className="separator">
                                |
                              </span>
                              <span className="review-content">
                                229 review
                              </span>
                            </div>
                            <a className="hover-effect" to="/"><span className="font-16-semibold">Buy
                              Now</span></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </PageWrapper>
  )
}

export default SingleShope