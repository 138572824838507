import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom'
import BreadcrumbHeader from '../../components/BreadcrumbHeader'
import PageWrapper from '../../hoc/PageWrapper'
import axiosInstance from '../../utils/axiosInstance'
import LeftProfile from './sections/LeftProfile'
import Topic from '../../components/Topic'
import Preloader from '../../components/Preloader'
import RightProfile from './sections/RightProfile'
import toastInstance from '../../utils/toast'
import ToastComponent from '../../components/ToastComponent'

const TopicDetails = () => {
  const [post, setPost] = useState(null)
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    setLoading(true)
    const getPost = location.state?.post
    if (getPost) {
      setPost(getPost)
    } else {
      axiosInstance.get(`/post/${id}`)
        .then(res => {
          setLoading(false)
          if (res.data.post) setPost(res.data.post)
          console.log('res.data.data', res.data.post)
        }).catch(err => {
          setLoading(false)
          toastInstance('An error occurred while getting post', true)
        })
    }
  }, [id])

  const handleComment = ({ postId, content }) => {
    axiosInstance.post(`/comment/${postId}`, { content })
      .then(res => {
        setPost(res.data.data)
        console.log('res.data', res.data)
        toastInstance('Your comment has been sibmitted')
      }).catch(err => {
        toastInstance('Post not submitted, try again', true)
      })
  }

  const handleLike = (id) => {
    axiosInstance.patch(`/post/like/${id}`)
      .then(res => {
        setPost(res.data.data)
        console.log('res.data', res.data)
      }).catch(err => {
        toastInstance('An error occurred while liking post', true)
      })
  }

  const handleDeletePost = (postId) => {
    axiosInstance.delete(`/post/${postId}`)
      .then(res => {
        if (res.data.statusCode === 200) {
          setPost(null)
          navigate('/community')
        }
      }).catch(err => {
        toastInstance('Post not deleted, try again', true)
      })
  }

  const handleDeleteComment = (commentId) => {
    axiosInstance.delete(`/comment/${commentId}`)
      .then(res => {
        const newComments = post.comments.filter(comment => comment._id !== res.data.data._id)
        console.log('newComments', newComments)
        const newPost = { ...post, comments: newComments }
        setPost(newPost)
        toastInstance('Comment deleted')
        console.log('res.data', res.data)
      }).catch(err => {
        console.log('err.response', err.response)
        toastInstance('Comment not deleted, try again', true)
      })
  }

  return (
    <>
      <PageWrapper>
        <BreadcrumbHeader header={'Community'} links={[<Link to='/community'>Community</Link>, 'Topic']} />
        <div className="single-community-menu">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div style={{ textAlign: 'left' }} className="top-menu">
                  <a onClick={() => navigate(-1)} style={{ cursor: 'pointer' }} className="link">
                    <i className="fas fa-arrow-left" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="profile-section single-community">
          <div className="container">
            <div className="row justify-content-center">
              <LeftProfile />
              <div className="col-lg-6">
                {!loading ?
                  <>{post ?
                    <div className="profile-main-content">
                      <Topic post={post} handleDelete={handleDeletePost} handleLike={handleLike}
                        openComment={true} handleComment={handleComment} handleDeleteComment={handleDeleteComment} />
                    </div> :
                    <div className="alert alert-primary">
                      <span>Sorry, no post found.</span>
                    </div>}</>
                  : <Preloader />}
              </div>
              <RightProfile />
            </div>
          </div>
        </section>
        <ToastComponent />
      </PageWrapper>
    </>
  )
}

export default TopicDetails