import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import BreadcrumbHeader from '../../components/BreadcrumbHeader'
import PageWrapper from '../../hoc/PageWrapper'
import ShopCategory from './sections/ShopCategory'
import Pagination from '../../components/Pagination'
import ShopFilter from './sections/ShopFilter'
import Product from './sections/Product'
import AltProduct from './sections/AltProduct'
import { GlobalContext } from '../../contexts/Provider'

const Shop3 = () => {
  const { authState: { productData } } = useContext(GlobalContext)

  return (
    <PageWrapper>
      <BreadcrumbHeader header={'Details'} links={[<Link to='/'>Home</Link>, 'Shop']} />
      <section className="product-category">
        <div className="container">
          <div className="row">
            <ShopCategory />
            <div className="col-lg-9">
              <ShopFilter />
              <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-gird" role="tabpanel"
                  aria-labelledby="pills-gird-tab">
                  <div className="row">
                    {productData.map((product, i) => (
                      <Product key={i} id={product.id} img={`../../${product.img}`} name={product.name}
                        reviewsNo={product.reviewsNo} price={product.price} />
                    ))}
                  </div>
                </div>
                <div className="tab-pane fade" id="pills-list" role="tabpanel" aria-labelledby="pills-list-tab">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="spl-wrapper">
                        {productData.map((product, i) => (
                          <AltProduct key={i} id={product.id} img={`../../${product.img}`} name={product.name}
                            reviewsNo={product.reviewsNo} price={product.price} />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Pagination />
            </div>
          </div>
        </div>
      </section>
    </PageWrapper>
  )
}

export default Shop3