import React from 'react'
import { Link } from 'react-router-dom'
import PageWrapper from '../hoc/PageWrapper'
import img404 from "../assets/images/404.png"

const Page404 = () => {
  return (
    <PageWrapper>
      <section className="section-404">
        <div className="container">
          <div className="thumb-404">
            <img src={img404} alt="404" />
          </div>
          <h3 className="title">Oops.. looks like you got lost :( </h3>
          <Link to="/" className="custom-button">Back To Home <i className="flaticon-right"></i></Link>
        </div>
      </section>
    </PageWrapper>
  )
}

export default Page404