import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import BreadcrumbHeader from '../../components/BreadcrumbHeader'
import ToastComponent from '../../components/ToastComponent'
import PageWrapper from '../../hoc/PageWrapper'
import { GlobalContext } from '../../contexts/Provider'
import LeftSection from './sections/LeftSection'

const Messanger = () => {
  const { authState: { user } } = useContext(GlobalContext)

  return (
    <PageWrapper>
      <BreadcrumbHeader header={'Community'} links={[<Link to='/'>Home</Link>, 'Messanger']} />
      <section className="profile-section">
        <div className="container">
          <div className="row justify-content-center">
            <LeftSection />
            <div className="col-xl-5 col-lg-6">
            </div>
            <div className="col-xl-3 col-lg-5 col-md-7">
            </div>
          </div>
        </div>
      </section>
      <ToastComponent />
    </PageWrapper>
  )
}

export default Messanger