import React from 'react'
import { Link } from 'react-router-dom'

const Member = ({ name, time, img, id }) => {
  return (
    <div className="col-lg-6">
      <div className="single-friend">

        <img style={{ borderRadius: '10px', maxWidth: 80, maxHeight: 100 }} src={img} alt="" />
        <div className="content">
          <Link to={`/user-profile/${id}?tab=activity`} className="name">
            {name}
            <span className="isvarify">
              <i className="fas fa-check-circle"></i>
            </span>
          </Link>
          <p className="date">{time}</p>
          <Link to={`/user-profile/${id}?tab=activity`} className="connnect-btn">
            Not Connected
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Member