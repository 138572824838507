import React from 'react'
import { Link } from 'react-router-dom'
import BreadcrumbHeader from '../../components/BreadcrumbHeader'
import PageWrapper from '../../hoc/PageWrapper'

const ProfileNotfound = () => {
	return (
		<PageWrapper>
			<BreadcrumbHeader header='My Profile' links={[<Link to='/'>Home</Link>, 'Profile']} />
			<section className="profile-section">
				<div className="container">
					<div className="alert alert-primary mt-4" role="alert">
						<span>Sorry, there was no user found. Please try another.</span>
					</div>
				</div>
			</section>
		</PageWrapper>
	)
}

export default ProfileNotfound