import React from 'react'
import { Link } from 'react-router-dom'

const AltProduct = ({ id, img, reviewsNo, price, name }) => {
  return (
    <div className="my-col">
      <div className="single-product-list">
        <div className="img">
          <img src={img} alt="" />
          <span className="sale">Sale</span>
          <ul className="more-links">
            <li>
              <Link to={`/shop/${id}`}>
                <i className="fas fa-sliders-h"></i>
              </Link>
            </li>
            <li>
              <Link to={`/shop/${id}`}>
                <i className="fas fa-heart"></i>
              </Link>
            </li>
            <li>
              <Link to={`/shop/${id}`}>
                <i className="far fa-eye"></i>
              </Link>
            </li>
          </ul>
        </div>
        <div className="content">
          <div className="content-inner">
            <div className="left-content">
              <span className="price">
                {price}
              </span>
              <h4 className="title">
                {name}
              </h4>
              <div className="review-area">
                <div className="stars">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                </div>
                <span className="separator">
                  |
                </span>
                <span className="review-content">
                  {reviewsNo} review
                </span>
              </div>

              <Link to="/" className="add-wish-btn">
                <i className="fas fa-heart"></i> Add to wish list
              </Link>
            </div>
            <div className="right-content">
              <a className="hover-effect" to="single-shope"><span
                className="font-16-semibold">Buy Now</span></a>
              <Link to="/" className="more-info">More Info</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AltProduct