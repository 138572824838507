import React from 'react'
import { Link } from 'react-router-dom'
import localImage from "../../../../assets/avatar.jpeg"

const ProfileSection = ({ user, otherUser }) => {
  const date = new Date().getFullYear()

  return (
    <div className="col-xl-4 col-lg-5 col-md-7">
      <div className="left-profile-area">
        <div className="profile-about-box">
          <div className="top-bg"></div>
          <div className="p-inner-content">
            <div className="profile-img">
              <Link to={`/user-profile/${user._id}`}>
                <img style={{ width: 120, height: 120 }} src={user.profileImage || localImage} alt="" />
                <div className="active-online"></div>
              </Link>
            </div>
            <h5 className="name">
              {`${user.firstname} ${user.lastname}`}
            </h5>
            <ul className="p-b-meta-one">
              <li>
                <span>{(date - Number(user.dob?.split('-')[0]))} Years Old</span>
              </li>
              <li>
                <span> <i className="fas fa-map-marker-alt"></i>{user.city}</span>
              </li>
            </ul>
            {otherUser ? null : <div className="p-b-meta-two">
              <div className="left">
                <div className="icon">
                  <i className="far fa-heart"></i>
                </div> 257
              </div>
              <div className="right">
                <Link to="/membership" className="custom-button">
                  <i className="fab fa-cloudversify"></i> Get Premium
                </Link>
              </div>
            </div>}
          </div>
        </div>
        {otherUser ? null : <div className="profile-meta-box">
          <ul className="p-m-b">
            <li>
              <Link to="/messanger" data-toggle="modal" data-target="#usermessage">
                <i className="far fa-envelope"></i>
              </Link>
            </li>
            <li>
              <Link to="/notification" data-toggle="modal" data-target="#usernotification">
                <i className="far fa-bell"></i>
              </Link>
            </li>
            <li>
              <Link to="/user-setting">
                <i className="fas fa-cogs"></i>
              </Link>
            </li>
          </ul>
          <div className="profile-uplodate-photo">
            <h4 className="p-u-p-header">
              <i className="fas fa-camera"></i> {user.profileImages.length} Uploaded Photos
            </h4>
            <div className="p-u-p-list">
              {user.profileImages.map((image, i) => (
                <div key={i} className="my-col">
                  <div className="img">
                    {console.log('image.url', image.url)}
                    <img src={image.url} alt="" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>}
      </div>
    </div>
  )
}

export default ProfileSection