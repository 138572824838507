import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import TimeAgo from "javascript-time-ago"
import en from "javascript-time-ago/locale/en"
import BreadcrumbHeader from '../../components/BreadcrumbHeader'
import Preloader from '../../components/Preloader'
import PageWrapper from '../../hoc/PageWrapper'
import axiosInstance from '../../utils/axiosInstance'
import Member from './sections/Member'
import localImage from '../../assets/avatar.jpeg'
import toastInstance from '../../utils/toast'
import ToastComponent from '../../components/ToastComponent'


const Members = () => {
  TimeAgo.addLocale(en)
  const timeAgo = new TimeAgo("en-US")
  const [members, setMembers] = useState([])
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [limit, setLimit] = useState(8)

  useEffect(() => {
    setLoading(true)
    axiosInstance.get(`/user?sort=-createdAt&limit=${limit}`)
      .then(res => {
        if (res.data.data) setMembers(res.data.data)
        if (res.data.count) setCount(res.data.count)
        setLoading(false)
      }).catch(err => {
        setLoading(false)
        toastInstance('Cannot fetch members', true)
      })
  }, [])

  const getMoreMembers = () => {
    axiosInstance.get(`/user?sort=-createdAt&limit=${limit}`)
      .then(res => {
        if (res.data.data) setMembers(res.data.data)
        if (res.data.count) setCount(res.data.count)
        setLoading(false)
      }).catch(err => {
        toastInstance('Cannot fetch more members', true)
      })
  }

  const loadMore = () => {
    setLimit(limit + 5)
    getMoreMembers()
  }

  return (
    <>
      <PageWrapper>
        <BreadcrumbHeader header={'Members'} links={[<Link to="/">Home</Link>, 'Members']} />
        <section className="community-section inner-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="top-filter">
                  <div className="right">
                    <span className="span">
                      Order By :
                    </span>
                    <div className="filter-right">
                      <select className="nice-select select-bar">
                        <option value="">New</option>
                        <option value="">Latest Active</option>
                        <option value="">Old</option>
                        <option value="">Popular</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {!loading ?
                <>{members.length > 0 ?
                  <>
                    {members.map((member, i) => (
                      <>
                        {console.log(member)}
                        <Member key={i} id={member._id}
                          img={member.profileImage || localImage}
                          name={`${member.firstname} ${member.lastname}`}
                          time={timeAgo.format(new Date(member.createdAt).getTime(), 'twitter-now')} />
                      </>
                    ))}
                  </> :
                  <div className="alert alert-primary mt-4 m-auto">
                    <span>Sorry, no members yet.</span>
                  </div>
                }</> : <Preloader />}
            </div>
          </div>
          {count > members.length && <a onClick={loadMore} className="load-more">Load More..</a>}
        </section>
        <ToastComponent />
      </PageWrapper>
    </ >
  )
}

export default Members