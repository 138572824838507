import $ from "jquery";

const query = () => {
  $(function () {
    console.log('here at query')
    // Toggle serch
    $('.serch-icon').on('click', function () {
      $('.search-overlay').show('slow');
    });

    $('.search-overlay .close').on('click', function () {
      $('.search-overlay').hide('slow');
    })

    //Faq
    $('.faq-wrapper .faq-title').on('click', function (e) {
      var element = $(this).parent('.faq-item');
      if (element.hasClass('open')) {
        element.removeClass('open');
        element.find('.faq-content').removeClass('open');
        element.find('.faq-content').slideUp(300, "swing");
      } else {
        element.addClass('open');
        element.children('.faq-content').slideDown(300, "swing");
        element.siblings('.faq-item').children('.faq-content').slideUp(300, "swing");
        element.siblings('.faq-item').removeClass('open');
        element.siblings('.faq-item').find('.faq-title, .faq-title-two').removeClass('open');
        element.siblings('.faq-item').find('.faq-content').slideUp(300, "swing");
      }
    });

    //MenuBar
    $('.header-bar').on('click', function () {
      $(".menu").toggleClass("active");
      $(".header-bar").toggleClass("active");
      $('.overlay').toggleClass('active');
    });
    $('.overlay').on('click', function () {
      $(".menu").removeClass("active");
      $(".header-bar").removeClass("active");
      $('.overlay').removeClass('active');
    });
    //Menu Dropdown Icon Adding
    $("ul>li>.submenu").parent("li").addClass("menu-item-has-children");
    // drop down menu width overflow problem fix
    $('ul').parent('li').hover(function () {
      var menu = $(this).find("ul");
      var menupos = $(menu).offset();
      if (menupos.left + menu.width() > $(window).width()) {
        var newpos = -$(menu).width();
        menu.css({
          left: newpos
        });
      }
    });
    $('.menu li a').on('click', function (e) {
      var element = $(this).parent('li');
      if (element.hasClass('open')) {
        element.removeClass('open');
        element.find('li').removeClass('open');
        element.find('ul').slideUp(300, "swing");
      } else {
        element.addClass('open');
        element.children('ul').slideDown(300, "swing");
        element.siblings('li').children('ul').slideUp(300, "swing");
        element.siblings('li').removeClass('open');
        element.siblings('li').find('li').removeClass('open');
        element.siblings('li').find('ul').slideUp(300, "swing");
      }
    })
    // Scroll To Top
    var scrollTop = $(".scrollToTop");
    $(window).on('scroll', function () {
      if ($(this).scrollTop() < 500) {
        scrollTop.removeClass("active");
      } else {
        scrollTop.addClass("active");
      }
    });

    //Click event to scroll to top
    $('.scrollToTop').on('click', function () {
      $('html, body').animate({
        scrollTop: 0
      }, 500);
      return false;
    });
    // Header Sticky Here
    var headerOne = $(".header-section");
    $(window).on('scroll', function () {
      if ($(this).scrollTop() < 1) {
        headerOne.removeClass("header-active");
      } else {
        headerOne.addClass("header-active");
      }
    });
    $('.window-warning .lay').on('click', function () {
      $('.window-warning').addClass('inActive');
    })
    $('.seat-plan-wrapper li .movie-schedule .item').on('click', function () {
      $('.window-warning').removeClass('inActive');
    })


    var width = $(window).width()
    if (width <= 991) {

      $(document).on('mouseover', function (e) {
        var container = $(".xzoom-preview");

        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          $(".xzoom-preview").css('display', 'none');
        }
      });

    }
  })
}

export default query
